import React from 'react';
import { useTranslation } from 'react-i18next';

import AudioHowl from '@phoenix7dev/play-music';
import { Button } from '@phoenix7dev/shared-components';

import { ISongs } from '../../config';
import { wrap } from '../../utils';
import styles from '../Settings/settings.module.scss';

import HistoryComponent from './HistoryComponent';

export const HistoryIcon: React.FC<{
  setType: React.Dispatch<React.SetStateAction<'menu' | 'info' | 'settings' | 'history'>>;
}> = ({ setType }) => (
  <div className={styles['settings-menu']}>
    <Button
      intent="history"
      onClick={() => {
        setType('history');
        AudioHowl.play({ type: ISongs.SFX_UI_MenuOpen });
      }}
    />
  </div>
);

const History: React.FC<{
  setType: React.Dispatch<React.SetStateAction<'menu' | 'info' | 'settings' | 'history'>>;
  show: boolean;
}> = ({ setType, show }) => {
  const { t } = useTranslation();

  const handleClosePopup = () => {
    setTimeout(() => {
      if (!AudioHowl.isPlaying(ISongs.SFX_UI_MenuOpen) && !AudioHowl.isPlaying(ISongs.SFX_UI_SpinStart)) {
        AudioHowl.play({ type: ISongs.SFX_UI_Close });
      }
    }, 0);
    setType('menu');
  };

  return (
    <div style={{ display: show ? 'block' : 'none' }}>
      <div className="popup__title">{t('gameHistory')}</div>
      <Button intent="close" className="popup__close" onClick={handleClosePopup} />
      <HistoryComponent />
    </div>
  );
};

export default History;
