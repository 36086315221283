export const CAROUSEL_CONTROLS_COLOR = 0xffffff;
export const CAROUSEL_DOTS_DEFAULT_COLOR = '#ffffff';
export const CAROUSEL_DOTS_ACTIVE_COLOR = '#fcef24';
export const CAROUSEL_DOTS_BLUR_SIZE = 5;
export const CAROUSEL_DOTS_SIZE = 24;
export const CAROUSEL_DOTS_GAP = 10;
export const CAROUSEL_ARROWS_SIZE = 24;
export const CAROUSEL_TEXT_SIZE = 20;
export const CAROUSEL_LETTER_SIZE = 30;
export const CAROUSEL_TEXT_LINE_HEIGHT = 25;
export const CAROUSEL_TEXT_COLOR = 0xffffff;
export const CAROUSEL_TEXT_SHADOW = 0x304a50;
export const CAROUSEL_TEXT_DIVIDE_COLOR = 0xfef000;
export const CAROUSEL_TEXT_WORD_WRAP_WIDTH = undefined;
export const CAROUSEL_ANIMATION_DURATION = 500;
