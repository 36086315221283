import 'pixi-spine';

import AudioHowl from '@phoenix7dev/play-music';

import { isMobile } from 'mobile-device-detect';
import { ISongs, mappedAudioSprites } from '../../config';
import { EventTypes, FreeSpinsTitleProps, ISettledBet, MessageBannerProps, UserBonus } from '../../global.d';
import { client, getUserBonuses, setCurrentBonus, setGameMode, setIsTimeoutErrorMessage, setUserLastBetResult, slotBetGql } from '../../gql';
import { ResourceTypes } from '../../resources.d';
import { isFreeSpinsMode } from '../../utils';
import SpineAnimation from '../animations/spine';
import Tween from '../animations/tween';
import ViewContainer from '../components/container';
import {
  DESKTOP_REELS_FRAME_HEIGHT,
  DESKTOP_REELS_FRAME_WIDTH,
  GAME_CONTAINER_HEIGHT,
  GAME_CONTAINER_WIDTH,
  LINES_NUMBER_CONTAINER_WIDTH,
  REELS_FRAME_POSITION_X,
  REELS_FRAME_POSITION_Y,
  SHOW_LINES_NUMBER_CONTAINER,
  SLOTS_CONTAINER_HEIGHT,
  SLOTS_CONTAINER_WIDTH,
  SLOT_HEIGHT,
  eventManager,
} from '../config';
import { IGameContainer } from '../d';
import FreeSpinsPanel from '../freeSpinsPanel/freeSpinsPanel';
import MessageBanner from '../messageBanner/messageBanner';
import { MessageWinBanner } from '../messageBanner/messageWinBanner';
import { RetriggerMessageBanner } from '../messageBanner/retriggerMessageBanner';
import CoinsAnimationContainer from '../winAnimations/coinsAnimationContainer';

import GameTitle from './gameTitle';
import GameReplay from './gameReplay';

window.PIXI = PIXI;

class GameView extends ViewContainer {
  public leftLinesContainer: PIXI.Container | null = null;

  public rightLinesContainer: PIXI.Container | null = null;

  public winSlotsContainer: PIXI.Container;

  public miniPayTableContainer: PIXI.Container;

  public reelsBackgroundContainer: PIXI.Container;

  public reelsContainer: PIXI.Container;

  public tintContainer: PIXI.Container;

  public slotsContainer: PIXI.Container;

  public winLabelContainer: PIXI.Container;

  public slotStopDisplayContainer: PIXI.Container;

  public winCountUpMessage: PIXI.Container;

  public gameTitle: PIXI.Container;

  public gameReplay: PIXI.Container;

  public coinsAnimationContainer: PIXI.Container;

  public frame: PIXI.Sprite;

  public freeSpinsPanel: FreeSpinsPanel | undefined;

  constructor(props: IGameContainer) {
    super();
    this.width = GAME_CONTAINER_WIDTH;
    this.height = GAME_CONTAINER_HEIGHT;
    this.slotsContainer = new PIXI.Container();
    this.slotsContainer.width = SLOTS_CONTAINER_WIDTH;
    this.slotsContainer.height = SLOTS_CONTAINER_HEIGHT;
    this.slotsContainer.x = SHOW_LINES_NUMBER_CONTAINER ? LINES_NUMBER_CONTAINER_WIDTH : 0;
    this.slotsContainer.y = 94;
    this.slotsContainer.sortableChildren = true;
    this.slotsContainer.scale.set(1, 1);
    this.slotsContainer.interactive = true;
    this.gameTitle = new GameTitle();
    this.gameReplay = new GameReplay();
    this.coinsAnimationContainer = new CoinsAnimationContainer();
    this.winLabelContainer = props.winLabelContainer;
    this.winSlotsContainer = props.winSlotsContainer;
    this.winSlotsContainer.y = this.slotsContainer.y;
    this.slotStopDisplayContainer = props.slotStopDisplayContainer;
    this.slotStopDisplayContainer.y = this.slotsContainer.y;
    this.miniPayTableContainer = props.miniPayTableContainer;
    this.miniPayTableContainer.x = this.slotsContainer.x;
    this.miniPayTableContainer.y = this.slotsContainer.y;
    this.tintContainer = props.tintContainer;
    this.reelsBackgroundContainer = props.reelsBackgroundContainer;
    this.reelsContainer = props.reelsContainer;
    this.winCountUpMessage = props.winCountUpMessage;
    this.slotsContainer.addChild(this.tintContainer);
    this.slotsContainer.addChild(this.reelsBackgroundContainer);
    this.slotsContainer.addChild(this.reelsContainer);
    this.slotsContainer.mask = new PIXI.Graphics()
      .beginFill(0xffffff)
      .drawRect(0, 0, SLOTS_CONTAINER_WIDTH, SLOTS_CONTAINER_HEIGHT)
      .endFill();
    this.slotsContainer.addChild(this.slotsContainer.mask);
    this.addChild(this.slotsContainer);
    this.frame = isMobile ? this.initMobileReelsFrame() : this.initDesktopReelsFrame();
    this.addChild(this.frame);
    this.initReelAnimation();
    this.addChild(this.slotStopDisplayContainer);
    this.addChild(this.miniPayTableContainer);
    this.addChild(this.gameTitle);
    this.addChild(this.gameReplay);
    this.addChild(this.winSlotsContainer);
    this.addChild(this.coinsAnimationContainer);
    this.addChild(this.winLabelContainer);
    this.addChild(this.winCountUpMessage);

    eventManager.addListener(EventTypes.RESIZE_GAME_CONTAINER, this.resize.bind(this));
    eventManager.addListener(EventTypes.CREATE_FREE_SPINS_TITLE, this.createFreeSpinsTitle.bind(this));
    eventManager.addListener(EventTypes.REMOVE_FREE_SPINS_TITLE, this.removeFreeSpinsTitle.bind(this));
    eventManager.addListener(EventTypes.CREATE_MESSAGE_BANNER, this.createFreeSpinsMessage.bind(this));
    eventManager.addListener(EventTypes.CREATE_RETRIGGER_MESSAGE_BANNER, this.createRetriggerMessage.bind(this));
    eventManager.addListener(EventTypes.CREATE_WIN_MESSAGE_BANNER, this.createWinMessage.bind(this));
     // override endFreeSpinMethod
     eventManager.addListener(EventTypes.SET_LAST_BET_RESULT_AFTER_FREE_SPINS, async () => {
      if (isFreeSpinsMode(setGameMode())) {
        if (setCurrentBonus().isActive && setCurrentBonus().rounds === setCurrentBonus().currentRound) {
          const res = await client.query<{
            userBonuses: UserBonus[];
          }>({
            query: getUserBonuses,
            variables: { input: { id: setCurrentBonus().id } },
            fetchPolicy: 'network-only',
          });
          const { betId } = res.data.userBonuses[0];
          const bet = await client.query<ISettledBet>({
            query: slotBetGql,
            variables: { input: { id: betId } },
            fetchPolicy: 'network-only',
          });
          setUserLastBetResult(bet.data.bet);
        }
      }
    });

  }

  private initReelAnimation(): void {
    const animation = new SpineAnimation({}, PIXI.Loader.shared.resources.reels_animation.spineData);
    animation.spine.scale.set(0.99, 0.95);
    animation.spine.x = this.frame.width / 2 - 48;
    animation.spine.y = this.frame.height / 2 + 50;
    this.addChild(animation.spine);
    animation.spine.state.setAnimation(0, 'reels_animation', true);
  }

  private getPositionOfFreeSpinPanelInScene(): number {
    const position = this.children.findIndex((child) => child instanceof FreeSpinsPanel);

    return position !== -1 ? position : 5;
  }

  private createFreeSpinsMessage(props: MessageBannerProps): void {
    this.addChildAt(new MessageBanner(props), this.getPositionOfFreeSpinPanelInScene());
  }

  private createWinMessage(
    props: MessageBannerProps & {
      totalWin: number;
      totalSpins: number;
      level: number;
    },
  ): void {
    if (setIsTimeoutErrorMessage()) return;
    const totalWinDelay = Tween.createDelayAnimation(mappedAudioSprites['003_21_TotalWinBanner'].duration);
    const level = setGameMode();
    totalWinDelay.addOnStart(() => {
      AudioHowl.fadeOut(1000, ISongs[`BGM_FS${level}_Loop` as keyof typeof ISongs], 0);
    });
    totalWinDelay.addOnComplete(() => {
      AudioHowl.stop({
        type: ISongs[`BGM_FS${level}_Loop` as keyof typeof ISongs],
      });
    });
    totalWinDelay.addOnSkip(() => {
      AudioHowl.stop({
        type: ISongs[`BGM_FS${level}_Loop` as keyof typeof ISongs],
      });
      AudioHowl.fadeOut(1000, ISongs.TotalWinBanner);
    });
    AudioHowl.play({ type: ISongs.TotalWinBanner, stopPrev: true });

    totalWinDelay.start();
    this.addChildAt(
      new MessageWinBanner({
        ...props,
        callback: () => {
          totalWinDelay.skip();
          if (props.callback) props.callback();
        },
      }).init(),
      this.getPositionOfFreeSpinPanelInScene(),
    );
  }

  private createRetriggerMessage(props: MessageBannerProps & { level: number; lastLevelRetriger: boolean }): void {
    this.addChildAt(new RetriggerMessageBanner(props).init(), this.getPositionOfFreeSpinPanelInScene());
  }

  private removeFreeSpinsTitle(): void {
    if (this.freeSpinsPanel) {
      this.removeChild(this.freeSpinsPanel);
      this.freeSpinsPanel?.destroy({
        children: true,
      });
      this.freeSpinsPanel = undefined;
    }
  }

  private createFreeSpinsTitle(props: FreeSpinsTitleProps): void {
    this.freeSpinsPanel = new FreeSpinsPanel(props);
    this.addChildAt(this.freeSpinsPanel, this.getPositionOfFreeSpinPanelInScene());
  }

  private initDesktopReelsFrame() {
    const frame = new PIXI.Sprite(PIXI.Texture.from(ResourceTypes.frame));
    const ratio = frame.height / frame.width;
    frame.height = DESKTOP_REELS_FRAME_HEIGHT * ratio;
    frame.width = DESKTOP_REELS_FRAME_WIDTH;
    frame.y = REELS_FRAME_POSITION_Y;
    frame.x = REELS_FRAME_POSITION_X;
    return frame;
  }

  private initMobileReelsFrame() {
    const frame = new PIXI.Sprite(PIXI.Texture.from(ResourceTypes.frame));
    const ratio = frame.height / frame.width;
    frame.height = DESKTOP_REELS_FRAME_HEIGHT * ratio;
    frame.width = DESKTOP_REELS_FRAME_WIDTH;
    frame.y = REELS_FRAME_POSITION_Y;
    frame.x = REELS_FRAME_POSITION_X;
    return frame;
  }

  private resize(width: number, height: number): void {
    this.scale.set(
      width / SLOTS_CONTAINER_WIDTH,
      (width * (SLOTS_CONTAINER_HEIGHT / SLOTS_CONTAINER_WIDTH)) / SLOTS_CONTAINER_HEIGHT,
    );
  }
}

export default GameView;
