import React, { useEffect, useRef, useState } from 'react';

import { EventTypes } from '../../global.d';
import { setIsProcessToGame, setSkipIntroScreen } from '../../gql';
import { eventManager } from '../../slotMachine/config';

import IntroScreen from './introScreen';
import styles from './introScreen.module.scss';

const IntroScreenLayout: React.FC = () => {
  const [isShowContent, setShowContent] = useState(setSkipIntroScreen());
  const introScreen = useRef<IntroScreen | null>(null);
  const pixiContainerRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    IntroScreen.initIntroScreen();
    introScreen.current = IntroScreen.getInstance();
    pixiContainerRef.current?.appendChild(introScreen.current.getApplication().view);
    eventManager.on(EventTypes.HANDLE_DESTROY_INTRO_SCREEN, () => {
      setShowContent(true);
    });
  }, []);

  if (isShowContent) {
    setIsProcessToGame(true);

    return null;
  }

  return <div className={styles.canvas} ref={pixiContainerRef} />;
};

export default React.memo(IntroScreenLayout);
