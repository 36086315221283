/* eslint-disable react-hooks/exhaustive-deps */
import { useLazyQuery, useQuery, useReactiveVar } from '@apollo/client';
import * as PIXI from 'pixi.js';
import React, { useEffect, useRef } from 'react';

import { EventTypes, GameMode, ISettledBet, IUserBalance } from '../../global.d';
import {
  configGql,
  getSlotGql,
  getUserGql,
  setBetAmount,
  setBrokenGame,
  setCoinAmount,
  setCoinValue,
  setCurrentBonus,
  setGameMode,
  setIsErrorMessage,
  setIsRevokeThrowingError,
  setIsSlotBusy,
  setIsSpinInProgress,
  setIsTimeoutErrorMessage,
  setNextResult,
  setPrevReelsPosition,
  setReelSetId,
  setSlotConfig,
  setUserLastBetResult,
  setWinAmount,
} from '../../gql';
import { IConfig, ISlotConfig } from '../../gql/d';
import SlotMachine from '../../slotMachine';
import {
  APPLICATION_TRANSPARENT,
  GAME_CONTAINER_HEIGHT,
  GAME_CONTAINER_WIDTH,
  SlotMachineState,
  eventManager,
} from '../../slotMachine/config';
import { ISlotData } from '../../slotMachine/d';
import { findSubstituteCoinAmount, getSpinResult4X5, isFreeSpinsMode, wrap } from '../../utils';

import { IPixiViewParentNode } from './d';
import styles from './slotMachineLayout.module.scss';

export const application = new PIXI.Application({
  resolution: window.devicePixelRatio || 1,
  autoDensity: true,
  transparent: APPLICATION_TRANSPARENT,
  width: GAME_CONTAINER_WIDTH,
  height: GAME_CONTAINER_HEIGHT,
});
const SlotMachineLayout: React.FC = () => {
  const { data: clientData } = useQuery<IConfig>(configGql);
  const { isMiniPayTable } = clientData!;
  const { id } = useReactiveVar<ISlotConfig>(setSlotConfig);
  const slotMachine = useRef<SlotMachine | null>(null);
  const pixiContainerRef = useRef<HTMLDivElement | null>(null);
  const { data: userData } = useQuery<{ user: IUserBalance }>(getUserGql);

  const { data } = useQuery<{ slot: ISlotData }, { input: { id: string } }>(getSlotGql, {
    variables: { input: { id } },
    onCompleted({ slot }) {
      const lines = slot.lines.map((_, index) => index);
      setSlotConfig({
        ...setSlotConfig(),
        clientSettings: slot.clientSettings,
        icons: slot.icons,
        reels: slot.reels,
        winLines: slot.lines,
        lines,
        lineSet: slot.lineSets[0],
      });
      // todo add logic to pick gamemode and reelsetid
      setReelSetId(slot.reels[0].id);
      setGameMode(GameMode.REGULAR);
      let coinValue;
      let coinAmount;
      if (setBrokenGame()) {
        const currentBonus = setCurrentBonus();
        coinValue = currentBonus.coinValue;
        coinAmount = currentBonus.coinAmount;
      } else {
        const lastBetCoinAmount = setUserLastBetResult().id ? setUserLastBetResult().coinAmount : 1;
        coinAmount = findSubstituteCoinAmount(lastBetCoinAmount, slot.clientSettings.coinAmounts.default);
        coinValue = slot.clientSettings.coinValues.find((elem) => elem.code === userData?.user.balance.currency)
          ?.variants[0];
      }
      setCoinValue(coinValue);
      setCoinAmount(coinAmount);
      setWinAmount(setUserLastBetResult().result.winCoinAmount);
      setBetAmount(coinAmount * slot.lineSets[0].coinAmountMultiplier);
      SlotMachine.initSlotMachine(slot, wrap(setIsSpinInProgress, false), wrap(setIsSlotBusy, false), application);
      // Overriding these methods to avoid certified files.
      SlotMachine.getInstance().setResult = (result: ISettledBet) => {
        const spinResult = getSpinResult4X5({
          reelPositions: result.bet.result.reelPositions.slice(0, 5),
          reelSet: setSlotConfig().reels.find(
            (reelSet) => reelSet.id === result.bet.reelSet.id,
          )!,
          icons: setSlotConfig().icons,
        });
        const newResult = {
          ...result,
          bet: {
            ...result.bet,
            result: {
              ...result.bet.result,
              spinResult,
            },
          },
        };
        SlotMachine.getInstance().nextResult = newResult;
        setPrevReelsPosition(newResult.bet.result.reelPositions);
        setUserLastBetResult(newResult.bet);
        setNextResult(newResult);
        SlotMachine.getInstance().miniPayTableContainer.setSpinResult(
          SlotMachine.getInstance().nextResult!.bet.result.spinResult,
        );
        if (!isFreeSpinsMode(setGameMode())) {
          eventManager.emit(
            EventTypes.UPDATE_USER_BALANCE,
            SlotMachine.getInstance().nextResult!.balance.placed,
          );
        }
      };

      SlotMachine.getInstance().throwTimeoutError = () => {
        if (!setIsRevokeThrowingError()) {
          setIsTimeoutErrorMessage(true);
          setIsErrorMessage(true);
        }
        eventManager.emit(EventTypes.THROW_ERROR);
      };

      SlotMachine.getInstance().onSpinStop = () => {
        if (setIsErrorMessage()) {
          SlotMachine.getInstance().state = SlotMachineState.IDLE;
          eventManager.emit(EventTypes.DISABLE_PAY_TABLE, !isFreeSpinsMode(setGameMode()));
          eventManager.emit(EventTypes.SLOT_MACHINE_STATE_CHANGE, SlotMachineState.IDLE);
          setIsSpinInProgress(false);
          setIsErrorMessage(false);
        } else {
          wrap(setIsSpinInProgress, false)();
          SlotMachine.getInstance().state = SlotMachineState.JINGLE;
          eventManager.emit(EventTypes.DISABLE_PAY_TABLE, false);
          eventManager.emit(EventTypes.SLOT_MACHINE_STATE_CHANGE, SlotMachineState.JINGLE);
        }
      };
      slotMachine.current = SlotMachine.getInstance();
    },
  });

  const resize = (application: PIXI.Application) => (): void => {
    const parent = application.view.parentNode as IPixiViewParentNode;
    const width = parent?.clientWidth;
    const height = parent?.clientHeight;
    eventManager.emit(EventTypes.RESIZE, width, height);
  };

  useEffect((): (() => void) | undefined => {
    if (slotMachine.current) {
      pixiContainerRef.current?.appendChild(application.view);
      resize(application)();
      window.addEventListener(EventTypes.RESIZE, resize(application));
      return () => window.removeEventListener(EventTypes.RESIZE, resize(application));
    }
    return undefined;
  }, [!!data]);

  useEffect(() => {
    eventManager.emit(EventTypes.DISABLE_PAY_TABLE, isMiniPayTable);
    if (!isMiniPayTable) {
      eventManager.emit(EventTypes.DISABLE_ALL_MINI_PAY_TABLES);
    }
  }, [isMiniPayTable]);

  return <div className={styles.canvasWrapper} ref={pixiContainerRef} />;
};

export default React.memo(SlotMachineLayout);
function setBrokenBuyFeature(): boolean {
  throw new Error('Function not implemented.');
}

