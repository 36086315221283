import { gql } from '@apollo/client';

export const BET_RESULT = gql`
  fragment betResult on BetResult {
    reelPositions
    winCoinAmount
  }
`;

export const REEL_SET = gql`
  fragment reelSet on ReelSet {
    id
    layout
    cols
    rows
    additionalReelSets
    type
  }
`;

export const LINE_SET = gql`
  fragment lineSet on LineSet {
    id
    slotId
    lines
    coinAmountMultiplier
  }
`;

export const REPLAY_BET = gql`
  fragment bet on ReplayBet {
    id
    coinAmount
    coinValue
    slotId
    status
    userBonusId
    reelSetId
    data
    createdAt
    updatedAt
  }
`;

export const BET = gql`
  fragment bet on Bet {
    id
    coinAmount
    coinValue
    slotId
    status
    userBonusId
    reelSetId
    data
    createdAt
    updatedAt
  }
`;

export const USER_BONUS = gql`
  fragment userBonus on UserBonus {
    id
    bonusId
    betId
    status
    coinValue
    coinAmount
    rounds
    data
    totalWinAmount
    createdAt
    updatedAt
  }
`;

export const BONUS = gql`
  fragment bonus on Bonus {
    id
    reelSetId
    type
    title
    description
    coinAmount
    purchasable
    rounds
    data
  }
`;

export const SLOT = gql`
  fragment slot on Slot {
    id
    lines
    previewImage
  }
`;

export const SLOT_ICON = gql`
  fragment slotIcon on SlotIcon {
    id
    type
    combos {
      type
      pattern
      rewards {
        type
        multiplier
        count
        bonusId
      }
    }
  }
`;

export const SLOT_SETTINGS = gql`
  fragment slotSettings on SlotSettings {
    betLines {
      min
      max
    }
    startPosition
  }
`;

export const CLIENT_SLOT_SETTINGS = gql`
  fragment clientSlotSettings on ClientSlotSettings {
    coinAmounts {
      default
      quick
    }
    coinValues {
      code
      variants
    }
    features {
      id
      enabled
    }
    autoplay {
      options
      conditions {
        ...clientSlotAutoplayConditions
      }
    }
  }
`;

export const CLIENT_SLOT_AUTOPLAY_CONDITIONS = gql`
  fragment clientSlotAutoplayConditions on ClientSlotAutoplayConditions {
    stopOnAnyWin {
      enabled
    }
    stopIfFeatureIsWon {
      enabled
    }
    stopIfSingleWinExceeds {
      enabled
      multipliers
    }
    stopIfBalanceDecreasesBy {
      enabled
      multipliers
    }
    stopIfBalanceIncreasesBy {
      enabled
      multipliers
    }
  }
`;

export const USER = gql`
  fragment user on User {
    id
    clientId
  }
`;

export const USER_BALANCE = gql`
  fragment userBalance on UserBalance {
    amount
    currency
  }
`;

export const PAGE_INFO = gql`
  fragment pageInfo on PageInfo {
    count
    hasPreviousPage
    hasNextPage
    startCursor
    endCursor
  }
`;

export const GET_BET_SETTINGS = gql`
  fragment betSettings on Slot {
    clientSettings {
      coinAmounts {
        default
        quick
      }
    }
    lineSets {
      coinAmountMultiplier
    }
    lines
  }
`;

export const PAYLINE = gql`
  fragment payline on Payline {
    lineId
    winPositions
    amount
  }
`;
