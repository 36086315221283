import * as PIXI from 'pixi.js';

import { sliderValueTextStyle } from '../config';

class Slider extends PIXI.Container {
  private wrap: PIXI.Graphics;

  private slider: PIXI.Graphics;

  private progress: PIXI.Graphics;

  private knob: PIXI.Graphics;

  private value: PIXI.Text;

  private SLIDER_LENGTH: number;

  private SLIDER_WIDTH: number;

  private SLIDER_HEIGHT: number;

  private SLIDER_GAP: number;

  private zeroPosition: number;

  private isDragging: boolean;

  private isVertical: boolean;

  private hasValue: boolean;

  private callBack: CallableFunction | undefined;

  constructor(
    width: number,
    height: number,
    length: number,
    gap: number,
    isVertical = false,
    hasValue = true,
    callBack?: CallableFunction,
  ) {
    super();
    this.x = 0;
    this.y = 0;
    this.isVertical = isVertical;
    this.hasValue = hasValue;
    this.SLIDER_WIDTH = width;
    this.SLIDER_HEIGHT = height;
    this.SLIDER_LENGTH = length;
    this.SLIDER_GAP = gap;
    this.zeroPosition = this.isVertical ? this.y : this.x;
    this.isDragging = false;
    this.wrap = this.initWrap(height);
    this.slider = this.initSlider();
    this.progress = this.initProgress();
    this.knob = this.initKnob(14, 14);
    this.value = this.initValue();
    this.addChild(this.wrap);
    this.addChild(this.slider);
    this.addChild(this.progress);
    this.addChild(this.knob);
    this.addChild(this.value);
    this.callBack = callBack;

    this.interactive = true;
    this.buttonMode = true;
    this.on('mouseout', () => this.handleStopMoveEvent());
  }

  private initWrap = (size: number): PIXI.Graphics => {
    const wrapWidth = this.SLIDER_WIDTH + this.SLIDER_GAP;
    const rect = new PIXI.Graphics();
    rect.beginFill(0x0000000, 0.5);
    rect.width = size;
    rect.height = wrapWidth;
    const width = size;
    const height = wrapWidth;

    if (this.isVertical) {
      rect.x = 0;
      rect.y = -(this.SLIDER_GAP / 2);
      rect.moveTo(0, height);
      rect.moveTo(width, height);
      rect.bezierCurveTo(width, height - width * 0.65, 0, height - width * 0.65, 0, height);
      rect.lineTo(0, 0);
      rect.arc(width / 2, 0, -width / 2, 0, Math.PI);
    }
    rect.closePath();
    rect.endFill();

    rect.interactive = true;
    rect.buttonMode = true;
    rect.on('mousedown', this.handleClickOnWrap);
    return rect;
  };

  private initSlider = (): PIXI.Graphics => {
    const slider = new PIXI.Graphics();
    slider.lineStyle(2, 0x000000);
    if (this.isVertical) {
      slider.x = this.SLIDER_HEIGHT / 2;
      slider.moveTo(0, this.SLIDER_WIDTH);
    } else {
      slider.y = this.SLIDER_HEIGHT / 2;
      slider.moveTo(this.SLIDER_WIDTH, 0);
    }
    slider.lineTo(0, 0);

    return slider;
  };

  private initProgress = (): PIXI.Graphics => {
    const progress = new PIXI.Graphics();
    progress.lineStyle(2, 0xffffff);
    if (this.isVertical) {
      progress.x = this.SLIDER_HEIGHT / 2;
      progress.moveTo(0, this.SLIDER_WIDTH);
      progress.lineTo(0, 0);
      progress.pivot.set(0, this.SLIDER_WIDTH);
      progress.height = 0;
    } else {
      progress.y = this.SLIDER_HEIGHT / 2;
      progress.moveTo(this.SLIDER_WIDTH, 0);
      progress.lineTo(0, 0);
      progress.width = 0;
    }
    return progress;
  };

  private initValue = (): PIXI.Text => {
    const value = new PIXI.Text('0', sliderValueTextStyle);
    const gap = this.SLIDER_GAP / 4;
    value.anchor.set(0.5, 0.5);
    if (this.isVertical) {
      value.x = this.SLIDER_HEIGHT / 2;
      value.y = -gap * 1.25;
    } else {
      value.x = this.SLIDER_WIDTH + gap;
      value.y = this.SLIDER_HEIGHT / 2;
    }
    value.visible = this.hasValue;

    return value;
  };

  private handleStartMoveEvent = (): void => {
    this.alpha = 0.9;
    this.isDragging = true;
  };

  private handleStopMoveEvent = (): void => {
    this.alpha = 1;
    this.isDragging = false;
  };

  private handleKnobPositionChange = (positions: { x: number; y: number }): void => {
    const directionPosition = this.isVertical ? positions.y - this.y : positions.x - this.x;
    const newPosition = directionPosition - this.zeroPosition;
    const maxValue = this.zeroPosition + this.SLIDER_WIDTH;
    const minValue = this.zeroPosition;
    const border = directionPosition < maxValue + 20 && directionPosition > minValue - 20;
    const roundedKnobValue = newPosition > 0 ? Math.min(newPosition, maxValue - minValue) : 0;
    if (this.isVertical) {
      if (border) {
        this.knob.position.y = roundedKnobValue;
        this.progress.height = this.SLIDER_WIDTH - roundedKnobValue;
      }
    } else if (border) {
      this.knob.position.x = roundedKnobValue;
      this.progress.width = roundedKnobValue;
    }

    this.getSliderVal();
  };

  private handleClickOnWrap = (eventData: PIXI.InteractionEvent): void => {
    this.handleKnobPositionChange(eventData.data.global);
  };

  private handleMoveEvent = (eventData: PIXI.InteractionEvent): void => {
    if (this.isDragging) {
      this.handleKnobPositionChange(eventData.data.global);
    }
  };

  private initKnob(width: number, height: number): PIXI.Graphics {
    const knob = new PIXI.Graphics();
    knob.lineStyle(1, 0xffffff);
    knob.beginFill(0xffffff, 1);
    knob.drawEllipse(0, 0, width / 2, height / 2);
    knob.endFill();
    knob.interactive = true;
    knob.buttonMode = true;
    knob.position.x = this.isVertical ? this.SLIDER_HEIGHT / 2 : 0;
    knob.position.y = this.isVertical ? this.SLIDER_WIDTH : this.SLIDER_HEIGHT / 2;
    knob.on('mousedown', this.handleStartMoveEvent);
    knob.on('touchstart', this.handleStartMoveEvent);
    knob.on('mouseup', this.handleStopMoveEvent);
    knob.on('touchend', this.handleStopMoveEvent);
    knob.on('mouseupoutside', this.handleStopMoveEvent);
    knob.on('touchendoutside', this.handleStopMoveEvent);
    knob.on('mousemove', this.handleMoveEvent);
    knob.on('touchmove', this.handleMoveEvent);
    knob.on('mousedown', this.handleStartMoveEvent);

    return knob;
  }

  public getSliderVal = (): number => {
    const position = this.isVertical ? this.knob.position.y : this.knob.position.x;
    const value = this.isVertical
      ? this.SLIDER_LENGTH - position / (this.SLIDER_WIDTH / this.SLIDER_LENGTH)
      : position / (this.SLIDER_WIDTH / this.SLIDER_LENGTH);
    const roundedValue = value > 50 ? Math.ceil(value) : Math.floor(value);
    this.value.text = `${roundedValue}`;
    if (this.callBack) this.callBack(roundedValue / 100);
    return roundedValue;
  };

  public setSliderVal = (x: number): void => {
    const pos = this.SLIDER_WIDTH - (x * this.SLIDER_WIDTH) / this.SLIDER_LENGTH;

    if (this.isVertical) {
      this.knob.position.y = pos;
      this.progress.height = this.SLIDER_WIDTH - pos;
    } else {
      this.knob.position.x = this.SLIDER_WIDTH - pos;
      this.progress.width = this.SLIDER_WIDTH - pos;
    }

    this.getSliderVal();
  };

  public getSize = (): number => {
    return this.SLIDER_WIDTH + this.SLIDER_GAP / 2;
  };

  public setPosition = (size: number, x: number, y: number, parentPositionX: number, parentPositionY: number): void => {
    this.y = y;
    this.x = x;

    this.zeroPosition = this.isVertical ? parentPositionY : parentPositionX;

    if (this.isVertical) {
      this.wrap.width = size;
      this.wrap.destroy();
      this.wrap = this.initWrap(size);
      this.addChildAt(this.wrap, 0);
      this.value.x = size / 2;
      this.slider.x = size / 2;
      this.progress.x = size / 2;
      this.progress.y = this.SLIDER_WIDTH;
      this.knob.x = size / 2;
    } else {
      this.wrap.height = size;
      this.value.y = size / 2;
      this.slider.y = size / 2;
      this.progress.y = size / 2;
      this.knob.y = size / 2;
    }
  };
}
export default Slider;
