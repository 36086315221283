import * as PIXI from 'pixi.js';

import { isMobile } from 'mobile-device-detect';
import { EventTypes, FreeSpinsTitleProps } from '../../global.d';
import i18n from '../../i18next';
import { ResourceTypes } from '../../resources.d';
import { updateTextScale } from '../../utils/utils';
import Animation from '../animations/animation';
import { createZoomAnimation } from '../animations/helper';
import ViewContainer from '../components/container';
import { eventManager } from '../config';

import { FREE_SPIN_ANIMATION_DELAY, FREE_SPIN_ANIMATION_LOOP, FREE_SPIN_ANIMATION_SCALE } from './config';
import { spinsStyle, textStyle } from './textStyles';

export class FreeSpinCounter extends ViewContainer {
  private base: PIXI.Sprite;

  private textContainer: ViewContainer;

  private titleText: PIXI.Text;

  private spinsText: PIXI.Text;

  private pulsAnimation: Animation | null = null;

  private spinsAmount: string;

  private currentSpin: string;

  constructor(props: FreeSpinsTitleProps) {
    super();
    this.base = this.initBase();

    this.textContainer = new ViewContainer();
    this.titleText = this.initTitleText(props.text);
    this.spinsText = this.initSpinsText(props.spins, props.currentSpin);

    this.spinsAmount = props.spins;
    this.currentSpin = props.currentSpin;

    this.init();

    eventManager.addListener(
      EventTypes.HANDLE_UPDATE_FREE_SPINS_TITLE,
      (spins: string, curr: string, immediately = false): void => this.handleUpdate(spins, curr, immediately),
    );
  }

  private init(): void {
    this.addChild(this.base);

    this.textContainer.name = 'text';
    this.textContainer.addChild(this.titleText);
    this.textContainer.addChild(this.spinsText);
    this.textContainer.pivot.set(this.textContainer.width / 2, this.textContainer.height / 2);
    this.textContainer.position.set(this.base.width / 2 - 5, this.base.height / 2 + 5);
    this.addChild(this.textContainer);
    eventManager.emit(EventTypes.HANDLE_ACTIVE_FREE_SPINS_GAME, false);
  }

  private initBase(): PIXI.Sprite {
    return new PIXI.Sprite(PIXI.Texture.from(ResourceTypes.levelPanel));
  }

  private initTitleText(titleText?: string): PIXI.Text {
    const text = new PIXI.Text(i18n.t(titleText || ''), textStyle);
    text.anchor.set(0.5, 0.5);
    text.x = 130;
    text.y = 35;
    updateTextScale(text, isMobile  ? 280 : 280, isMobile  ? 200 : 200);

    return text;
  }
   
  private initSpinsText(spins?: string, currentSpin?: string): PIXI.Text {
    const spinsText = new PIXI.Text(this.formatSpins(spins, currentSpin), spinsStyle);
    spinsText.anchor.set(0.5, 0.5);
    spinsText.x = 325;
    spinsText.y = 35;
    updateTextScale(spinsText, isMobile  ? 100 : 100, isMobile  ? 100 : 150);

    return spinsText;
  }

  private handleUpdate(spins?: string, currentSpin?: string, immediately = false): void {
    if (this.spinsText.text === this.formatSpins(spins, currentSpin)) return;
    this.spinsText.text = this.formatSpins(spins, currentSpin);

    if (immediately) return;

    this.pulsAnimation = createZoomAnimation(
      this.spinsText,
      FREE_SPIN_ANIMATION_SCALE,
      FREE_SPIN_ANIMATION_DELAY,
      FREE_SPIN_ANIMATION_LOOP,
    );
    this.pulsAnimation?.start();
  }

  private formatSpins(spins?: string, currentSpin?: string): string {
    return `${currentSpin !== undefined ? currentSpin : this.currentSpin} / ${
      spins !== undefined ? spins : this.spinsAmount
    }`;
  }

  public destroy(options?: { children?: boolean; texture?: boolean; baseTexture?: boolean }): void {
    super.destroy(options);
    eventManager.removeListener(EventTypes.HANDLE_UPDATE_FREE_SPINS_TITLE);
    eventManager.emit(EventTypes.HANDLE_ACTIVE_FREE_SPINS_GAME, true);
  }
}
