import { ResourceTypes } from '../resources.d';

export const config = {
  failureRetries: 3,
  autoplay: {
    stopOnWinExceedsMinValue: 0,
    stopOnBalanceIncreaseMinValue: 0,
    stopOnBalanceDecreaseMinValue: 0,
    stopOnWinExceedsMaxValue: 500,
    stopOnBalanceIncreaseMaxValue: 5000,
    stopOnBalanceDecreaseMaxValue: 1000,
    defaultValues: {
      isStopOnAnyWin: false,
      isStopOnWinExceeds: false,
      isStopOnBalanceIncrease: false,
      isStopOnBalanceDecrease: false,
      singleWinValue: 25,
      increaseValue: 25,
      decreaseValue: 25,
    },
    autoSpinValues: [25, 50, 75, 100, 500],
    timeOut: 1000,
  },
  betSettings: {
    mockedBets: [25, 50, 75, 100],
    minBet: 25,
  },
  historyTable: {
    showBy: 20,
    head: {
      gameId: 'Game ID',
      date: 'date',
      // balanceStart: 'Start balance',
      bet: 'bet',
      win: 'win',
      // balanceEnd: 'End Balance',
      // detail: 'Detail',
    },
  },
  payTableData: [
    {
      slug: ResourceTypes.wild,
      combos: [],
    },
    {
      slug: ResourceTypes.scatter,
      combos: [
        { pattern: 'x5', multiplier: 20 },
        { pattern: 'x4', multiplier: 5 },
        { pattern: 'x3', multiplier: 2 },
      ],
    },
    {
      slug: ResourceTypes.shark,
      combos: [
        { pattern: 'x5', multiplier: 120 },
        { pattern: 'x4', multiplier: 100 },
        { pattern: 'x3', multiplier: 80 },
        { pattern: 'x2', multiplier: 20 },
      ],
    },
    {
      slug: ResourceTypes.marlin,
      combos: [
        { pattern: 'x5', multiplier: 100 },
        { pattern: 'x4', multiplier: 80 },
        { pattern: 'x3', multiplier: 30 },
        { pattern: 'x2', multiplier: 8 },
      ],
    },
    {
      slug: ResourceTypes.dolphin,
      combos: [
        { pattern: 'x5', multiplier: 100 },
        { pattern: 'x4', multiplier: 80 },
        { pattern: 'x3', multiplier: 30 },
        { pattern: 'x2', multiplier: 8 },
      ],
    },
    {
      slug: ResourceTypes.seal,
      combos: [
        { pattern: 'x5', multiplier: 60 },
        { pattern: 'x4', multiplier: 40 },
        { pattern: 'x3', multiplier: 15 },
        { pattern: 'x2', multiplier: 4 },
      ],
    },
    {
      slug: ResourceTypes.manta,
      combos: [
        { pattern: 'x5', multiplier: 60 },
        { pattern: 'x4', multiplier: 40 },
        { pattern: 'x3', multiplier: 15 },
        { pattern: 'x2', multiplier: 4 },
      ],
    },
    {
      slug: ResourceTypes.turtle,
      combos: [
        { pattern: 'x5', multiplier: 50 },
        { pattern: 'x4', multiplier: 20 },
        { pattern: 'x3', multiplier: 4 },
      ],
    },
    {
      slug: ResourceTypes.octopus,
      combos: [
        { pattern: 'x5', multiplier: 50 },
        { pattern: 'x4', multiplier: 20 },
        { pattern: 'x3', multiplier: 4 },
      ],
    },
    {
      slug: ResourceTypes.clownFish,
      combos: [
        { pattern: 'x5', multiplier: 40 },
        { pattern: 'x4', multiplier: 15 },
        { pattern: 'x3', multiplier: 2 },
      ],
    },
    {
      slug: ResourceTypes.coral,
      combos: [
        { pattern: 'x5', multiplier: 40 },
        { pattern: 'x4', multiplier: 15 },
        { pattern: 'x3', multiplier: 2 },
      ],
    },
    {
      slug: ResourceTypes.jellyFish,
      combos: [
        { pattern: 'x5', multiplier: 30 },
        { pattern: 'x4', multiplier: 8 },
        { pattern: 'x3', multiplier: 2 },
      ],
    },
    {
      slug: ResourceTypes.shell,
      combos: [
        { pattern: 'x5', multiplier: 30 },
        { pattern: 'x4', multiplier: 8 },
        { pattern: 'x3', multiplier: 2 },
      ],
    },
  ],
};

export enum SlotId {
  A = 'A',
  B = 'B',
  C = 'C',
  D = 'D',
  E = 'E',
  F = 'F',
  G = 'G',
  H = 'H',
  I = 'I',
  J = 'J',
  K = 'K',
  WL = 'WL',
  SC1 = 'SC1',
  SC2 = 'SC2',
  SC3 = 'SC3',
  SC4 = 'SC4',
  SC5 = 'SC5',
  SC6 = 'SC6',
}
