import { useQuery } from '@apollo/client';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { config } from '../../config';
import { IUserBalance } from '../../global.d';
import { getBetAmountGql, getUserGql, setCoinValue, setSlotConfig } from '../../gql';
import { ResourceTypes } from '../../resources.d';
import { countCoins, formatNumber, showCurrency } from '../../utils';
import Resources from '../../utils/resources';

import { IPaytableData } from './d';
import styles from './info.module.scss';

const hasTranslate = (slug: ResourceTypes): boolean => {
  return slug === ResourceTypes.wild || slug === ResourceTypes.scatter;
};
const calcMultiplier = (multiplier: number, betAmount: number, slug: ResourceTypes): number => {
  if (slug === ResourceTypes.scatter)
    return countCoins({ totalAmount: betAmount, coinValue: setCoinValue() }) * multiplier;
  return (
    countCoins({
      totalAmount: betAmount / setSlotConfig().lineSet.coinAmountMultiplier,
      coinValue: setCoinValue(),
    }) * multiplier
  );
};

const PaytableComponent: React.FC = () => {
  const { t } = useTranslation();
  const { data: userData } = useQuery<{ user: IUserBalance }>(getUserGql);
  const { data: dataBet } = useQuery<{
    betAmount: number;
  }>(getBetAmountGql);

  const {
    user: { balance },
  } = userData!;

  const { betAmount } = dataBet!;

  const data: IPaytableData[] = config.payTableData;

  const { currency } = balance;

  return (
    <div className={styles['paytable-list']}>
      {data.map(({ combos, slug }) => (
        <div key={slug} className={styles['paytable-list__item']}>
          <div className={styles.img}>
            {hasTranslate(slug) && <div className={styles['paytable-list__title']}>{t(`infoPayTable_${slug}`)}</div>}
                 {/* dynamic used keys */}
            {/* t('infoPayTable_manta') */}
              {/* t('infoPayTable_octopus') */}
            {/* t('infoPayTable_shark') */}
            {/* t('infoPayTable_jellyFisht') */}
            {/* t('infoPayTable_sealt') */}
            {/* t('infoPayTable_shell') */}
            {/* t('infoPayTable_coral') */}
            {/* t('infoPayTable_clownFish') */}
            {/* t('infoPayTable_wild') */}
            {/* t('infoPayTable_scatter') */}
            {/* t('infoPayTable_marlin') */}
            {/* t('infoPayTable_dolphin') */}
            {/* t('infoPayTable_turtle') */}
            <img draggable="false" alt={slug} src={Resources.getSource(slug)} />
            <div className={styles['paytable-list__title']}>{t(`infoPayTable_${slug}`)}</div>
          </div>
          <div className={styles.content}>
            {combos.map((i) => (
              <div key={i.pattern}>
                <span className={styles.multiplier}>{i.pattern} </span>
                {formatNumber(currency, calcMultiplier(i.multiplier, betAmount, slug), showCurrency(currency))}
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};

export default PaytableComponent;
