import React from 'react';
import { useTranslation } from 'react-i18next';

import styles from './info.module.scss';

const FreeSpinFeature: React.VFC = () => {
  const { t } = useTranslation();

  return (
    <section className={styles.feature}>
      <h1 className={styles.title}>{t('infoFeatureFreeSpinTitle')}</h1>
      <div
        className={`${styles.p} ${styles.margin}`}
        dangerouslySetInnerHTML={{ __html: t('infoFeatureFreeSpinDesc1') }}
      />
      <div
        className={`${styles.p} ${styles.margin}`}
        dangerouslySetInnerHTML={{ __html: t('infoFeatureFreeSpinDesc2') }}
      />
      <div
        className={`${styles.p} ${styles.margin}`}
        dangerouslySetInnerHTML={{ __html: t('infoFeatureFreeSpinDesc3') }}
      />
      <ul>
        <li className={`${styles.p} ${styles.left}`} dangerouslySetInnerHTML={{ __html: t('infoFeatureLevel1') }} />
        <li className={`${styles.p} ${styles.left}`} dangerouslySetInnerHTML={{ __html: t('infoFeatureLevel2') }} />
        <li className={`${styles.p} ${styles.left}`} dangerouslySetInnerHTML={{ __html: t('infoFeatureLevel3') }} />
        <li className={`${styles.p} ${styles.left}`} dangerouslySetInnerHTML={{ __html: t('infoFeatureLevel4') }} />
        <li className={`${styles.p} ${styles.left}`} dangerouslySetInnerHTML={{ __html: t('infoFeatureLevel5') }} />
      </ul>
      <div
        className={`${styles.p} ${styles.left}`}
        dangerouslySetInnerHTML={{ __html: t('infoFeatureFreeSpinNextDesc') }}
      />
      <div
        className={`${styles.p} ${styles.left}`}
        dangerouslySetInnerHTML={{ __html: t('infoFeatureFreeSpinNext') }}
      />
    </section>
  );
};

const Features: React.VFC = () => {
  return (
    <div className={styles.gameRules}>
      <FreeSpinFeature />
    </div>
  );
};

export default Features;
