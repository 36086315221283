import { SlotId } from '../../config';
import { EventTypes, ReelSet } from '../../global.d';
import { setPrevReelsPosition } from '../../gql';
import { ResourceTypes } from '../../resources.d';
import ViewContainer from '../components/container';
import {
  ANTICIPATION_DURATION,
  ANTICIPATION_REEL_ENDING_SLOTS_AMOUNT,
  BASE_SPIN_TIME,
  FORCE_STOP_SPIN_ANIMATION_DURATION,
  FORCE_STOP_SPIN_PER_EACH_DURATION,
  INIT_SLOTS_AMOUNT_SPIN_BEFORE_STOP,
  REELS_AMOUNT,
  REEL_ENDING_SLOTS_AMOUNT,
  REEL_WIDTH,
  ReelState,
  SLOTS_CONTAINER_HEIGHT,
  SLOTS_CONTAINER_WIDTH,
  SLOTS_PER_REEL_AMOUNT,
  SLOT_HEIGHT,
  TURBO_SPIN_TIME,
  eventManager,
} from '../config';
import { Icon } from '../d';

import Reel from './reel';

class ReelsContainer extends ViewContainer {
  public reels: Reel[] = [];

  public forcedStop = false;

  constructor(reels: SlotId[][], startPosition: number[]) {
    super();
    this.initContainer();
    this.initReelsBackground();
    this.initDelimiter();
    this.initReels(reels, startPosition);
    eventManager.emit(EventTypes.REGISTER_ANIMATOR, this.reelAnimator.bind(this), PIXI.UPDATE_PRIORITY.INTERACTION);
    eventManager.addListener(EventTypes.ROLLBACK_REELS, this.rollbackReels.bind(this));
    eventManager.addListener(EventTypes.SHOW_STOP_SLOTS_DISPLAY, this.hideSlots.bind(this));
    eventManager.addListener(EventTypes.HIDE_STOP_SLOTS_DISPLAY, this.showSlots.bind(this));
    eventManager.addListener(EventTypes.SETUP_REEL_POSITIONS, this.setupAnimationTarget.bind(this));
    eventManager.addListener(EventTypes.FORCE_STOP_REELS, this.forceStopReels.bind(this));
    eventManager.addListener(EventTypes.CHANGE_REEL_SET, this.changeReelSet.bind(this));
    this.sortableChildren = true;
  }

  private rollbackReels(positions: number[]): void {
    for (let i = 0; i < positions.length; i++) {
      eventManager.emit(EventTypes.REMOVE_TWEEN_ANIMATION, this.reels[i].spinAnimation?.getStarting());
      eventManager.emit(EventTypes.REMOVE_TWEEN_ANIMATION, this.reels[i].spinAnimation?.getFakeRolling());
      this.reels[i].position = this.reels[i].size - positions[i];
      this.reels[i].state = ReelState.IDLE;
    }
  }

  private hideSlots(spinResult: Icon[], reelId?: number): void {
    const arr = [];
    if (reelId !== undefined) {
      for (let i = 0; i < SLOTS_PER_REEL_AMOUNT; i++) {
        arr.push(i * REELS_AMOUNT + reelId);
      }
    } else {
      for (let i = 0; i < REELS_AMOUNT * SLOTS_PER_REEL_AMOUNT; i++) {
        arr.push(i);
      }
    }
    this.setSlotsVisibility(arr, false);
  }

  private showSlots(): void {
    const arr = [];
    for (let i = 0; i < REELS_AMOUNT * SLOTS_PER_REEL_AMOUNT; i++) arr.push(i);
    this.setSlotsVisibility(arr, true);
  }

  private initContainer(): void {
    this.width = SLOTS_CONTAINER_WIDTH;
    this.height = SLOTS_CONTAINER_HEIGHT;
  }

  private changeReelSet(settings: { reelSet: ReelSet; reelPositions: number[] }): void {
    setPrevReelsPosition(settings.reelPositions.slice(0, 5));
    const reelPositions = settings.reelPositions
      .slice(0, 5)
      .map((position, idx) => (settings.reelSet.layout[idx].length - position) % settings.reelSet.layout[idx].length);

    for (let i = 0; i < REELS_AMOUNT; i++) {
      this.reels[i].clean();
      this.reels[i].init(settings.reelSet.layout[i], reelPositions[i]);
    }
  }

  private initReelsBackground(): void {
    for (let i = 0; i < REELS_AMOUNT; i++) {
      const container = new ViewContainer();
      const light = new PIXI.Sprite(PIXI.Texture.from(ResourceTypes[`reelsLight${i + 1}` as ResourceTypes]));

      light.width = REEL_WIDTH;
      light.height = SLOTS_CONTAINER_HEIGHT;
      light.x = 0;
      light.y = 0;
      container.addChild(light);
      container.x = REEL_WIDTH * i;
      this.addChild(container);
    }
  }

  private initDelimiter(): void {
    const container = new ViewContainer();
    container.width = this.width;
    container.height = this.height;
    for (let i = 0; i < REELS_AMOUNT - 1; i++) {
      const delimiter = new PIXI.Sprite(PIXI.Texture.from(ResourceTypes.delimiter));
      delimiter.rotation = Math.PI / 2;
      delimiter.x = REEL_WIDTH * (i + 1) + 3;
      container.addChild(delimiter);
    }
    this.addChild(container);
  }

  private initReels(reels: SlotId[][], startPosition?: number[]): void {
    for (let i = 0; i < REELS_AMOUNT; i++) {
      const position = startPosition ? startPosition[i] : 0;
      const reel = new Reel(i, reels[i], position);
      this.reels[i] = reel;
      this.addChild(reel.container);
    }
  }

  private forceStopReels(isTurboSpin: boolean): void {
    this.forcedStop = true;
    const stopAllReelsAtSameTime =
      Date.now() - this.reels[0].spinAnimation!.startTime < (isTurboSpin ? TURBO_SPIN_TIME : BASE_SPIN_TIME);
    for (let i = 0; i < this.reels.length; i++) {
      if (stopAllReelsAtSameTime && i !== 0) {
        this.reels[i].isPlaySoundOnStop = false;
      }
      this.reels[i].stopReel(
        stopAllReelsAtSameTime
          ? FORCE_STOP_SPIN_ANIMATION_DURATION
          : FORCE_STOP_SPIN_ANIMATION_DURATION + i * FORCE_STOP_SPIN_PER_EACH_DURATION,
      );
    }
  }

  private prolongTarget = (reel: Reel, minValue: number): number => {
    let res = 0;
    while (res < minValue) res += reel.data.length;
    return res;
  };

  private setupAnimationTarget(
    reelPositions: Array<number>,
    scatterNo: Array<number>,
    anticipationStartReelId: number,
    anticipationEndReelId: number,
  ): void {
    for (let j = 0; j < this.reels.length; j++) {
      const fakeRollingAnimation = this.reels[j].spinAnimation!.getFakeRolling();
      const rollingAnimation = this.reels[j].spinAnimation!.getRolling();
      const endingAnimation = this.reels[j].spinAnimation!.getEnding();
      let target = this.reels[j].getTarget(this.reels[j].data.length - reelPositions[j]);
      fakeRollingAnimation.duration = 0;
      this.reels[j].scatter_no = scatterNo[j];
      if (j > anticipationStartReelId) {
        let beginValue =
          target -
          INIT_SLOTS_AMOUNT_SPIN_BEFORE_STOP -
          ANTICIPATION_REEL_ENDING_SLOTS_AMOUNT -
          j * 5 -
          (j - anticipationStartReelId - 1) * (this.reels[j].isTurboSpin ? 150 : 55);
        if (beginValue < 0) {
          const prolong = this.prolongTarget(this.reels[j], Math.abs(beginValue));
          beginValue += prolong;
          target += prolong;
        }
        rollingAnimation.propertyBeginValue = beginValue;

        rollingAnimation.target = target - ANTICIPATION_REEL_ENDING_SLOTS_AMOUNT;
        rollingAnimation.duration +=
          ANTICIPATION_DURATION *
          (Math.min(j - anticipationStartReelId, anticipationEndReelId - anticipationStartReelId) - 1);
        endingAnimation.propertyBeginValue = target - ANTICIPATION_REEL_ENDING_SLOTS_AMOUNT;
        endingAnimation.target = target;
        if (j === anticipationStartReelId + 1) {
          endingAnimation.addOnStart(() => eventManager.emit(EventTypes.ANTICIPATION_ANIMATIONS_START));
        }
        if (j === Math.min(REELS_AMOUNT - 1, anticipationEndReelId - 1)) {
          endingAnimation.addOnComplete(() => eventManager.emit(EventTypes.ANTICIPATION_ANIMATIONS_END));
        }
        if (j < anticipationEndReelId) {
          endingAnimation.duration = ANTICIPATION_DURATION;
          endingAnimation.addOnStart(() => eventManager.emit(EventTypes.ANTICIPATION_STARTS, j));
        }
      } else {
        rollingAnimation.propertyBeginValue =
          target - INIT_SLOTS_AMOUNT_SPIN_BEFORE_STOP - REEL_ENDING_SLOTS_AMOUNT - j * 5;
        rollingAnimation.target = target - REEL_ENDING_SLOTS_AMOUNT;
        endingAnimation.propertyBeginValue = target - REEL_ENDING_SLOTS_AMOUNT;
        endingAnimation.target = target;
      }
    }
  }

  private setSlotsVisibility(slots: number[], visibility: boolean): void {
    slots.forEach((slotId) => {
      const x = slotId % REELS_AMOUNT;
      const y = Math.floor(slotId / REELS_AMOUNT);
      const position = this.reels[x].size - (Math.round(this.reels[x].position) % this.reels[x].size) + y - 1;
      const normalizedPosition = position === -1 ? this.reels[x].size - 1 : position % this.reels[x].size;
      const slot = this.reels[x].slots[normalizedPosition];
      if (slot) slot.visible = visibility;
    });
  }

  public reelAnimator(): void {
    for (let i = 0; i < this.reels.length; i++) {
      this.reels[i].previousPosition = this.reels[i].position;

      // Update symbol positions on reel.
      for (let j = 0; j < this.reels[i].slots.length; j++) {
        const slot = this.reels[i].slots[j];
        slot.y = ((this.reels[i].position + j + 2) % this.reels[i].slots.length) * SLOT_HEIGHT - SLOT_HEIGHT - 19;

        slot.toggleBlur(this.reels[i].state === ReelState.ROLLING);
      }
    }
  }
}

export default ReelsContainer;
