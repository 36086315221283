import i18n from 'i18next';
import * as _ from 'lodash';
import * as PIXI from 'pixi.js';

import { isMobile } from 'mobile-device-detect';
import { EventTypes, MessageBannerProps } from '../../global.d';
import { ResourceTypes } from '../../resources.d';
import { updateTextScale } from '../../utils/utils';
import ViewContainer from '../components/container';
import {  GAME_CONTAINER_WIDTH, eventManager } from '../config';

import { BANNER_HEIGHT, BANNER_POSITION_X, BANNER_POSITION_Y, BANNER_WIDTH } from './config';
import {
  btnTextStyle,
  descriptionTextStyle,
  freeSpinsTitleLevelStyles,
  subtitleTextStyle,
  titleTextStyle,
} from './textStyles';

class MessageBanner extends ViewContainer {
  public banner: PIXI.Sprite;

  private title: PIXI.Text;

  private description: PIXI.Text;

  private btn: PIXI.Text;

  private spins: PIXI.Text;

  private titleLevel: PIXI.Text;

  constructor(props: MessageBannerProps) {
    super();
    this.x = BANNER_POSITION_X;
    this.y = BANNER_POSITION_Y;
    this.visible = true;
    this.interactive = true;
    this.on('click', () => {
      if (props.callback) {
        props.callback();
        props.callback = undefined;
      }
      if (!props.preventDefaultDestroy) this.handleDestroy();
    });
    this.on('touchstart', () => {
      if (props.callback) {
        props.callback();
        props.callback = undefined;
      }
      if (!props.preventDefaultDestroy) this.handleDestroy();
    });
    eventManager.addListener(EventTypes.SPACEKEY_CLOSE_MESSAGE_BANNER, () => {
      if (props.callback) {
        props.callback();
        props.callback = undefined;
      }
      if (!props.preventDefaultDestroy) this.handleDestroy();
    });
    this.banner = this.initBanner();
    this.title = this.initTitle(props.title);
    this.titleLevel = this.initTitleLevel(props.titleLevel);
    this.spins = this.initSpins(props.freeSpinsSubtitle);
    this.description = this.initDescription(props.subTitle);
    this.btn = this.initBtn(props.btnText);
    this.init();
  }

  private init(): void {
    this.addChild(this.banner);
    this.addChild(this.title);
    this.addChild(this.description);
    this.addChild(this.btn);
    this.addChild(this.spins);
  }

  private initBanner(): PIXI.Sprite {
    const banner = new PIXI.Sprite(PIXI.Texture.from(ResourceTypes.messageBanner));
    banner.width = BANNER_WIDTH;
    banner.height = BANNER_HEIGHT;

    return banner;
  }

  private initTitle(titleText?: string): PIXI.Text {
    const title = new PIXI.Text(i18n.t(titleText || ''), titleTextStyle);
    title.y = 180;
    title.x = 700;
    title.anchor.set(0.5, 0.5);
    updateTextScale(title, isMobile ? 750 : 800, isMobile ? 350 : 250);
    title.resolution = 1;
    title.visible = !!titleText;

    return title;
  }

  private initTitleLevel(freeSpinsTitleLevel?: string): PIXI.Text {
    const titleLevel = new PIXI.Text(i18n.t(freeSpinsTitleLevel || ''), freeSpinsTitleLevelStyles);
    titleLevel.y = 100;
    titleLevel.x = GAME_CONTAINER_WIDTH / 2 - titleLevel.width / 2 + 100;
    titleLevel.anchor.set(0.5, 0.5);
    titleLevel.resolution = 1;
    updateTextScale(titleLevel, isMobile ? 750 : 800, isMobile ? 350 : 250);
    titleLevel.visible = !!freeSpinsTitleLevel;

    return titleLevel;
  }

  private initSpins(freeSpinsSubtitle?: string): PIXI.Text {
    const spins = new PIXI.Text(i18n.t(freeSpinsSubtitle || ''), subtitleTextStyle);
    spins.visible = !!spins;
    spins.anchor.set(0.5, 0.5);
    spins.resolution = 1;
    updateTextScale(spins, isMobile ? 750 : 800, isMobile ? 350 : 250);
    spins.y = 325;
    spins.x = 690;
    updateTextScale(spins, 800, 400);

    return spins;
  }

  private initDescription(descriptionText?: string): PIXI.Text {
    const description = new PIXI.Text(i18n.t(descriptionText || ''), descriptionTextStyle);
    description.visible = !!descriptionText;
    description.y = 450;
    description.x = 230;
    description.width = BANNER_WIDTH - 400;
    description.resolution = 1;
    return description;
  }

  private initBtn(btnText?: string): PIXI.Text {
    const btn = new PIXI.Text(i18n.t(btnText || 'Tap to START'), btnTextStyle);
    btn.y = 680;
    btn.x = 700;
    btn.anchor.set(0.5, 0.5);
    updateTextScale(btn, isMobile ? 700 : 700, isMobile ? 350 : 250);

    return btn;
  }

  public handleDestroy(): void {
    eventManager.removeAllListeners(EventTypes.SPACEKEY_CLOSE_MESSAGE_BANNER);
    this.destroy({ children: true, texture: false, baseTexture: false });
  }
}

export default MessageBanner;
