import { ResourceTypes } from '../resources.d';
import Resources from '../utils/resources';

import { SlotId } from './config';

export enum SymbolAnimationType {
  DEFAULT,
  SPRITE,
  SPINE,
}
export const MAPPED_SYMBOLS = {
  [SlotId.A]: ResourceTypes.shark,
  [SlotId.B]: ResourceTypes.marlin,
  [SlotId.C]: ResourceTypes.dolphin,
  [SlotId.D]: ResourceTypes.seal,
  [SlotId.E]: ResourceTypes.manta,
  [SlotId.F]: ResourceTypes.turtle,
  [SlotId.G]: ResourceTypes.octopus,
  [SlotId.H]: ResourceTypes.clownFish,
  [SlotId.I]: ResourceTypes.coral,
  [SlotId.J]: ResourceTypes.jellyFish,
  [SlotId.K]: ResourceTypes.shell,
  [SlotId.WL]: ResourceTypes.wild,
  [SlotId.SC1]: ResourceTypes.scatter,
  [SlotId.SC2]: ResourceTypes.scatter,
  [SlotId.SC3]: ResourceTypes.scatter,
  [SlotId.SC4]: ResourceTypes.scatter,
  [SlotId.SC5]: ResourceTypes.scatter,
  [SlotId.SC6]: ResourceTypes.scatter,
};
export const MAPPED_BLURRED_SYMBOLS = {
  [SlotId.A]: ResourceTypes.blurShark,
  [SlotId.B]: ResourceTypes.blurMarlin,
  [SlotId.C]: ResourceTypes.blurDolphin,
  [SlotId.D]: ResourceTypes.blurSeal,
  [SlotId.E]: ResourceTypes.blurManta,
  [SlotId.F]: ResourceTypes.blurTurtule,
  [SlotId.G]: ResourceTypes.blurOctopus,
  [SlotId.H]: ResourceTypes.blurClownFish,
  [SlotId.I]: ResourceTypes.blurCoral,
  [SlotId.J]: ResourceTypes.blurJellyFish,
  [SlotId.K]: ResourceTypes.blurShell,
  [SlotId.WL]: ResourceTypes.wild,
  [SlotId.SC1]: ResourceTypes.scatter,
  [SlotId.SC2]: ResourceTypes.scatter,
  [SlotId.SC3]: ResourceTypes.scatter,
  [SlotId.SC4]: ResourceTypes.scatter,
  [SlotId.SC5]: ResourceTypes.scatter,
  [SlotId.SC6]: ResourceTypes.scatter,
};

export const MAPPED_SYMBOLS_STOP_ANIMATIONS: {
  [key in SlotId]?: {
    type: SymbolAnimationType;
    src?: string;
    animation?: string;
  };
} = {
  [SlotId.SC1]: {
    type: SymbolAnimationType.SPINE,
    src: 'all_symbols',
    animation: 'scatter_announce',
  },
  [SlotId.SC2]: {
    type: SymbolAnimationType.SPINE,
    src: 'all_symbols',
    animation: 'scatter_announce',
  },
  [SlotId.SC3]: {
    type: SymbolAnimationType.SPINE,
    src: 'all_symbols',
    animation: 'scatter_announce',
  },
  [SlotId.SC4]: {
    type: SymbolAnimationType.SPINE,
    src: 'all_symbols',
    animation: 'scatter_announce',
  },
  [SlotId.SC5]: {
    type: SymbolAnimationType.SPINE,
    src: 'all_symbols',
    animation: 'scatter_announce',
  },
  [SlotId.SC6]: {
    type: SymbolAnimationType.SPINE,
    src: 'all_symbols',
    animation: 'scatter_announce',
  },
};

export const MAPPED_SYMBOLS_ANIMATIONS: {
  [key in SlotId]: {
    type: SymbolAnimationType;
    src?: string;
    animation?: string;
  };
} = {
  [SlotId.A]: {
    type: SymbolAnimationType.SPINE,
    src: 'all_symbols',
    animation: 'a_symbol_win',
  },
  [SlotId.B]: {
    type: SymbolAnimationType.SPINE,
    src: 'all_symbols',
    animation: 'b_symbol_win',
  },
  [SlotId.C]: {
    type: SymbolAnimationType.SPINE,
    src: 'all_symbols',
    animation: 'c_symbol_win',
  },
  [SlotId.D]: {
    type: SymbolAnimationType.SPINE,
    src: 'all_symbols',
    animation: 'd_symbol_win',
  },
  [SlotId.E]: {
    type: SymbolAnimationType.SPINE,
    src: 'all_symbols',
    animation: 'e_symbol_win',
  },
  [SlotId.F]: {
    type: SymbolAnimationType.SPINE,
    src: 'all_symbols',
    animation: 'f_symbol_win',
  },
  [SlotId.G]: {
    type: SymbolAnimationType.SPINE,
    src: 'all_symbols',
    animation: 'g_symbol_win',
  },
  [SlotId.H]: {
    type: SymbolAnimationType.SPINE,
    src: 'all_symbols',
    animation: 'h_symbol_win',
  },
  [SlotId.I]: {
    type: SymbolAnimationType.SPINE,
    src: 'all_symbols',
    animation: 'i_symbol_win',
  },
  [SlotId.J]: {
    type: SymbolAnimationType.SPINE,
    src: 'all_symbols',
    animation: 'j_symbol_win',
  },
  [SlotId.K]: {
    type: SymbolAnimationType.SPINE,
    src: 'all_symbols',
    animation: 'k_symbol_win',
  },
  [SlotId.WL]: {
    type: SymbolAnimationType.SPINE,
    src: 'all_symbols',
    animation: 'wild_win',
  },
  [SlotId.SC1]: {
    type: SymbolAnimationType.SPINE,
    src: 'all_symbols',
    animation: 'scatter_win',
  },
  [SlotId.SC2]: {
    type: SymbolAnimationType.SPINE,
    src: 'all_symbols',
    animation: 'scatter_win',
  },
  [SlotId.SC3]: {
    type: SymbolAnimationType.SPINE,
    src: 'all_symbols',
    animation: 'scatter_win',
  },
  [SlotId.SC4]: {
    type: SymbolAnimationType.SPINE,
    src: 'all_symbols',
    animation: 'scatter_win',
  },
  [SlotId.SC5]: {
    type: SymbolAnimationType.SPINE,
    src: 'all_symbols',
    animation: 'scatter_win',
  },
  [SlotId.SC6]: {
    type: SymbolAnimationType.SPINE,
    src: 'all_symbols',
    animation: 'scatter_win',
  },
};

export const LOADER_MAPPED_SYMBOLS = [
  { name: ResourceTypes.shark, src: Resources.getSource(ResourceTypes.shark) },
  {
    name: ResourceTypes.marlin,
    src: Resources.getSource(ResourceTypes.marlin),
  },
  {
    name: ResourceTypes.dolphin,
    src: Resources.getSource(ResourceTypes.dolphin),
  },
  {
    name: ResourceTypes.seal,
    src: Resources.getSource(ResourceTypes.seal),
  },
  { name: ResourceTypes.manta, src: Resources.getSource(ResourceTypes.manta) },
  {
    name: ResourceTypes.turtle,
    src: Resources.getSource(ResourceTypes.turtle),
  },
  {
    name: ResourceTypes.octopus,
    src: Resources.getSource(ResourceTypes.octopus),
  },
  {
    name: ResourceTypes.clownFish,
    src: Resources.getSource(ResourceTypes.clownFish),
  },
  {
    name: ResourceTypes.coral,
    src: Resources.getSource(ResourceTypes.coral),
  },
  {
    name: ResourceTypes.jellyFish,
    src: Resources.getSource(ResourceTypes.jellyFish),
  },
  {
    name: ResourceTypes.shell,
    src: Resources.getSource(ResourceTypes.shell),
  },
  { name: ResourceTypes.wild, src: Resources.getSource(ResourceTypes.wild) },
  {
    name: ResourceTypes.scatter,
    src: Resources.getSource(ResourceTypes.scatter),
  },
];

export const generateTexturePath = (before: string, after: string, isMobile: boolean): string => {
  return `${before}/${isMobile ? 'mobile' : 'desktop'}/${after}`;
};
export const SPINE_LOADER_TEXTURES = (isMobile: boolean) => {
  return [
    {
      name: 'ambient',
      src: generateTexturePath('/animations', 'ambient/ambient.json', isMobile),
      isSpine: true,
      spineImages: {
        desktop: ['ambient.png', 'ambient2.png', 'ambient3.jpg'],
        mobile: ['ambient.png', 'ambient2.jpg', 'ambient3.png'],
      },
    },
    {
      name: 'near_miss',
      src: generateTexturePath('/animations', 'near_miss/near_miss.json', isMobile),
      isSpine: true,
      spineImages: {
        desktop: ['near_miss.jpg'],
        mobile: ['near_miss.jpg'],
      },
    },
    {
      name: 'all_symbols',
      src: generateTexturePath('/animations', 'all_symbols/all_symbols.json', isMobile),
      isSpine: true,
      spineImages: {
        desktop: ['all_symbols.png', 'all_symbols1.png', 'all_symbols2.jpg'],
        mobile: ['all_symbols.png', 'all_symbols2.png', 'all_symbols3.jpg'],
      },
    },
    {
      name: 'intro_transition',
      src: generateTexturePath('/animations', 'intro_transition/intro_transition.json', isMobile),
      isSpine: true,
      spineImages: {
        desktop: ['intro_transition.png', 'intro_transition1.jpg', 'intro_transition2.png'],
        mobile: ['intro_transition.png', 'intro_transition1.jpg', 'intro_transition2.png'],
      },
    },
    {
      name: 'win_frame',
      src: generateTexturePath('/animations', 'win_frame/win_frame.json', isMobile),
      isSpine: true,
      spineImages: {
        desktop: ['win_frame.jpg'],
        mobile: ['win_frame.jpg'],
      },
    },
    {
      name: 'reels_animation',
      src: generateTexturePath('/animations', 'reels_animation/reels_animation.json', isMobile),
      isSpine: true,
      spineImages: {
        desktop: ['reels_animation.jpg'],
        mobile: ['reels_animation.jpg'],
      },
    },
    {
      name: 'coinsAnimation',
      src: generateTexturePath('/animations', 'coins/coins.json', isMobile),
    },
  ];
};

export const LOADER_TEXTURES = (isMobile: boolean) => [
  { name: ResourceTypes.frame, src: Resources.getSource(ResourceTypes.frame) },
  {
    name: ResourceTypes.delimiter,
    src: Resources.getSource(ResourceTypes.delimiter),
  },
  {
    name: ResourceTypes.reelsLight1,
    src: Resources.getSource(ResourceTypes.reelsLight1),
  },
  {
    name: ResourceTypes.reelsLight2,
    src: Resources.getSource(ResourceTypes.reelsLight2),
  },
  {
    name: ResourceTypes.reelsLight3,
    src: Resources.getSource(ResourceTypes.reelsLight3),
  },
  {
    name: ResourceTypes.reelsLight4,
    src: Resources.getSource(ResourceTypes.reelsLight4),
  },
  {
    name: ResourceTypes.reelsLight5,
    src: Resources.getSource(ResourceTypes.reelsLight5),
  },
  {
    name: 'slotBg',
    src: generateTexturePath('/images', 'background/slot_bg.jpg', isMobile),
  },
  {
    name: 'freeSpinsBg1',
    src: generateTexturePath('/images', 'background/free_spins_bg_1.jpg', isMobile),
  },
  {
    name: 'freeSpinsBg2',
    src: generateTexturePath('/images', 'background/free_spins_bg_2.jpg', isMobile),
  },
  {
    name: 'freeSpinsBg3',
    src: generateTexturePath('/images', 'background/free_spins_bg_3.jpg', isMobile),
  },
  {
    name: 'portrait_slotBg',
    src: generateTexturePath('/images', 'background/portrait_slot_bg.jpg', isMobile),
  },
  {
    name: 'portrait_freeSpinsBg1',
    src: generateTexturePath('/images', 'background/portrait_free_spins_bg_1.jpg', isMobile),
  },
  {
    name: 'portrait_freeSpinsBg2',
    src: generateTexturePath('/images', 'background/portrait_free_spins_bg_2.jpg', isMobile),
  },
  {
    name: 'portrait_freeSpinsBg3',
    src: generateTexturePath('/images', 'background/portrait_free_spins_bg_3.jpg', isMobile),
  },
  {
    name: ResourceTypes.introBg,
    src: Resources.getSource(ResourceTypes.introBg),
  },
  {
    name: ResourceTypes.intro1,
    src: Resources.getSource(ResourceTypes.intro1),
  },
  {
    name: ResourceTypes.intro2,
    src: Resources.getSource(ResourceTypes.intro2),
  },
  {
    name: ResourceTypes.intro3,
    src: Resources.getSource(ResourceTypes.intro3),
  },
  {
    name: ResourceTypes.buttonOk,
    src: Resources.getSource(ResourceTypes.buttonOk),
  },
  {
    name: 'buttonsSprite',
    src: '/images/buttons/buttons.json',
  },
  {
    name: 'infoBtnSprite',
    src: '/images/infoBtn/infomation.json',
  },
  {
    name: 'loaderSprite',
    src: '/images/loader/loader.json',
  },
  {
    name: ResourceTypes.messageBanner,
    src: Resources.getSource(ResourceTypes.messageBanner),
  },
  {
    name: ResourceTypes.messageWinBanner,
    src: Resources.getSource(ResourceTypes.messageWinBanner),
  },
  {
    name: ResourceTypes.messageBannerBlock,
    src: Resources.getSource(ResourceTypes.messageBannerBlock),
  },
  {
    name: ResourceTypes.messageBanerPointer,
    src: Resources.getSource(ResourceTypes.messageBanerPointer),
  },
  {
    name: ResourceTypes.symbolIndicatorA,
    src: Resources.getSource(ResourceTypes.symbolIndicatorA),
  },
  {
    name: ResourceTypes.symbolIndicatorB,
    src: Resources.getSource(ResourceTypes.symbolIndicatorB),
  },
  {
    name: ResourceTypes.symbolIndicatorC,
    src: Resources.getSource(ResourceTypes.symbolIndicatorC),
  },
  {
    name: ResourceTypes.symbolIndicatorD,
    src: Resources.getSource(ResourceTypes.symbolIndicatorD),
  },
  {
    name: ResourceTypes.symbolIndicatorE,
    src: Resources.getSource(ResourceTypes.symbolIndicatorE),
  },
  {
    name: ResourceTypes.levelPanel,
    src: Resources.getSource(ResourceTypes.levelPanel),
  },
  {
    name: ResourceTypes.blurBonus,
    src: Resources.getSource(ResourceTypes.blurBonus),
  },
  {
    name: ResourceTypes.blurClownFish,
    src: Resources.getSource(ResourceTypes.blurClownFish),
  },
  {
    name: ResourceTypes.blurCoral,
    src: Resources.getSource(ResourceTypes.blurCoral),
  },
  {
    name: ResourceTypes.blurDolphin,
    src: Resources.getSource(ResourceTypes.blurDolphin),
  },
  {
    name: ResourceTypes.blurJellyFish,
    src: Resources.getSource(ResourceTypes.blurJellyFish),
  },
  {
    name: ResourceTypes.blurManta,
    src: Resources.getSource(ResourceTypes.blurManta),
  },
  {
    name: ResourceTypes.blurMarlin,
    src: Resources.getSource(ResourceTypes.blurMarlin),
  },
  {
    name: ResourceTypes.blurOctopus,
    src: Resources.getSource(ResourceTypes.blurOctopus),
  },
  {
    name: ResourceTypes.blurSeal,
    src: Resources.getSource(ResourceTypes.blurSeal),
  },
  {
    name: ResourceTypes.blurShark,
    src: Resources.getSource(ResourceTypes.blurShark),
  },
  {
    name: ResourceTypes.blurShell,
    src: Resources.getSource(ResourceTypes.blurShell),
  },
  {
    name: ResourceTypes.blurTurtule,
    src: Resources.getSource(ResourceTypes.blurTurtule),
  },
  {
    name: ResourceTypes.blurWild,
    src: Resources.getSource(ResourceTypes.blurWild),
  },
];
