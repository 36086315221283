import * as PIXI from 'pixi.js';

import { EventTypes, GameMode } from '../../../global.d';
import { ResourceTypes } from '../../../resources.d';
import { destroySpine, isFreeSpinsMode } from '../../../utils';
import Animation from '../../animations/animation';
import SpineAnimation from '../../animations/spine';
import ViewContainer from '../../components/container';
import { ANTICIPATION_SLOTS_TINT, REELS_AMOUNT, REEL_WIDTH, SLOT_WIDTH, eventManager } from '../../config';

class ReelsBackgroundContainer extends ViewContainer {
  private lights: PIXI.Sprite[] = [];

  constructor() {
    super();

    this.initBackground();

    eventManager.addListener(EventTypes.ANTICIPATION_STARTS, this.onAnticipationStart.bind(this));
    eventManager.addListener(EventTypes.REELS_STOPPED, this.onReelsStoped.bind(this));
    // eventManager.addListener(
    //   EventTypes.CHANGE_MODE,
    //   this.onChangeMode.bind(this),
    // );
  }

  initBackground(): void {
    for (let i = 0; i < REELS_AMOUNT; i++) {
      const container = new PIXI.Container();
      const light = new PIXI.Sprite(PIXI.Texture.from(ResourceTypes[`reelsLight${i + 1}` as ResourceTypes]));
      light.width = 100;
      this.lights.push(light);
      container.y = 10;
      container.x = i * REEL_WIDTH + (REEL_WIDTH - SLOT_WIDTH) / 2;
      // container.addChild(light);
      this.addChild(container);
    }
  }

  private onAnticipationStart(): void {
    this.lights.forEach((light) => {
      light.tint = ANTICIPATION_SLOTS_TINT;
    });
  }

  private onReelsStoped(): void {
    this.lights.forEach((light) => {
      light.tint = 0xffffff;
    });
  }

  // private onChangeMode(settings: { mode: GameMode }) {
  //   this.lights.forEach((light, index) => {
  //     if (isFreeSpinsMode(settings.mode)) {
  //       light.texture = PIXI.Texture.from(
  //         ResourceTypes[`reelsLight${index + 1}Free` as ResourceTypes],
  //       );
  //     } else {
  //       light.texture = PIXI.Texture.from(
  //         ResourceTypes[`reelsLight${index + 1}` as ResourceTypes],
  //       );
  //     }
  //   });
  // }
}

export default ReelsBackgroundContainer;
