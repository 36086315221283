import i18n from 'i18next';
import * as PIXI from 'pixi.js';

import { isMobile } from 'mobile-device-detect';
import { MessageBannerProps } from '../../global';
import { setBrokenGame } from '../../gql';
import { ResourceTypes } from '../../resources.d';
import { updateTextScale } from '../../utils/utils';
import { GAME_CONTAINER_WIDTH } from '../config';

import { BaseMessageBanner } from './baseMessageBanner';
import { BANNER_HEIGHT, BANNER_WIDTH } from './config';
import { freeSpinsTotalSpinsStyles, freeSpinsTotalWinTitleStyles, winTotalSpinsTextStyles } from './textStyles';

export class MessageWinBanner extends BaseMessageBanner {
  protected props: MessageBannerProps & {
    totalWin: number;
    totalSpins: number;
    level: number;
  };

  private freeSpinsAmount: PIXI.Text;

  constructor(
    props: MessageBannerProps & {
      totalWin: number;
      totalSpins: number;
      level: number;
    },
  ) {
    super(props);
    this.props = props;
    this.freeSpinsAmount = this.initFreeSpinsAmount();
  }

  public init(): MessageWinBanner {
    super.init();
    if (this.props.level === 5 && !setBrokenGame()) {
      this.addChild(this.freeSpinsAmount);
    }
    return this;
  }

  protected initBanner(): PIXI.Sprite {
    const winBanner = new PIXI.Sprite(PIXI.Texture.from(ResourceTypes.messageWinBanner));
    winBanner.width = BANNER_WIDTH + 10;
    winBanner.height = BANNER_HEIGHT + 5;
    return winBanner;
  }

  protected initFreeSpinsAmount(): PIXI.Text {
    const winSubTitle = new PIXI.Text(
      `${this.props.totalSpins} ${i18n.t('freeSpinsTotalSpins' || '')}`,
      freeSpinsTotalSpinsStyles,
    );
    winSubTitle.anchor.set(0.5, 0.5);
    updateTextScale(winSubTitle, isMobile ? 700 : 750, isMobile ? 350 : 250);
    winSubTitle.y = 650;
    winSubTitle.x = 700;
    winSubTitle.visible = !!winSubTitle;
    return winSubTitle;
  }

  protected initTitle(titleText?: string): PIXI.Text {
    const winTitle = new PIXI.Text(`${i18n.t('freeSpinsTotalWinTitle' || '')} `, freeSpinsTotalWinTitleStyles);
    winTitle.anchor.set(0.5, 0.5);
    updateTextScale(winTitle, isMobile ? 700 : 750, isMobile ? 350 : 250);

    winTitle.y = 275;
    winTitle.x = 700;
    winTitle.visible = !!winTitle;
    return winTitle;
  }

  protected initDescription(descriptionText?: string): PIXI.Text {
    const winTotalSpins = new PIXI.Text(`${this.props.totalWin}`, winTotalSpinsTextStyles);
    winTotalSpins.y = 400;
    winTotalSpins.x = GAME_CONTAINER_WIDTH / 2 - winTotalSpins.width / 2 + 80;
    winTotalSpins.visible = !!winTotalSpins;
    return winTotalSpins;
  }

  protected initBtn(btnText?: string): PIXI.Text {
    const btn = new PIXI.Text('');

    return btn;
  }
}
