import * as PIXI from 'pixi.js';

const font = 'NotoSans-SemiCondensedBold';

export const levelTextStyle = new PIXI.TextStyle({
  fontFamily: font,
  dropShadow: true,
  dropShadowAlpha: 1,
  dropShadowBlur: 0,
  dropShadowColor: '#ff0000',
  fill: '#fff000',
  fontSize: 25.5,
  fontStyle: 'italic',
  fontWeight: 'bold',
  letterSpacing: 0,
});

export const maxLevelTextStyle = new PIXI.TextStyle({
  fontFamily: font,
  dropShadow: true,
  dropShadowAlpha: 1,
  dropShadowBlur: 0,
  dropShadowColor: '#ff0000',
  fill: '#fff000',
  fontSize: 48,
  fontStyle: 'italic',
  fontWeight: 'bold',
  letterSpacing: 0,
});

export const textStyle = {
  align: 'center',
  breakWords: true,
  dropShadow: true,
  dropShadowAlpha: 0.75,
  dropShadowAngle: 0.785,
  dropShadowBlur: 0,
  dropShadowColor: 0x000000,
  dropShadowDistance: 4,
  fill: 0xfff000,
  fontFamily: font,
  fontSize: 50,
  fontVariant: 'normal',
  leading: 0,
  lineJoin: 'round',
  miterLimit: 10,
  padding: 4,
  trim: false,
  textBaseline: 'alphabetic',
  whiteSpace: 'pre',
  wordWrap: false,
  wordWrapWidth: 1330,
  letterSpacing: 0,
  fontStyle: 'italic',
  fontWeight: 'bolder',
};

export const spinsStyle = {
  align: 'center',
  breakWords: true,
  dropShadow: true,
  dropShadowAlpha: 0.75,
  dropShadowAngle: 0.785,
  dropShadowBlur: 10,
  dropShadowColor: 0x000000,
  dropShadowDistance: 4,
  fill: 0xfff000,
  fontFamily: font,
  fontSize: 50,
  fontVariant: 'normal',
  leading: 0,
  lineJoin: 'round',
  miterLimit: 10,
  padding: 4,
  trim: false,
  textBaseline: 'alphabetic',
  whiteSpace: 'pre',
  wordWrap: false,
  wordWrapWidth: 1330,
  letterSpacing: 0,
  fontStyle: 'italic',
  fontWeight: 'bolder',
};
