import { useMutation, useQuery } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import { rebuildStorageCache } from '@phoenix7dev/utils-fe';

import AudioHowl from '@phoenix7dev/play-music';
import { Loader, ProgressBar } from '@phoenix7dev/shared-components';

import { ELoaderStages } from '@phoenix7dev/shared-components/dist/loader/d';
import { isMobile } from 'mobile-device-detect';
import {
  LOADER_MAPPED_SYMBOLS,
  LOADER_TEXTURES,
  SPINE_LOADER_TEXTURES,
  audioSprite,
  audioSpriteVolume,
} from '../../config';
import {
  BonusStatus,
  EventTypes,
  IAuthInput,
  IBonus,
  IUserBalance,
  UserBonus,
} from '../../global.d';
import {
  authGql,
  client,
  configGql,
  getBonuses,
  getProgressGql,
  getUserBonuses,
  getUserGql,
  setBonuses,
  setBrokenGame,
  setCurrency,
  setCurrentBonus,
  setCurrentFreeSpinsTotalWin,
  setIsAuthorized,
  setIsMobile,
  setIsPortrait,
  setIsSoundOn,
  setIsSuspended,
  setProgress,
  setIsEnabledSpaceSpin,
  setIsLeftHandMode,
  setIsMiniPayTable,
  setIsTurboSpin,
  setSkipIntroScreen,
  setSlotConfig,
  setUserLastBetResult,
  slotHistoryGql,
  userBonusBetsGql,
} from '../../gql';
import { IConfig, ISlotHistoryData } from '../../gql/d';
import { ResourceTypes } from '../../resources.d';
import { eventManager } from '../../slotMachine/config';
import {
  getGameModeByBonusId,
  getLevelByGameMode,
  isPortrait,
  loadErrorHandler,
  loadPixiAssets,
  parseQuery
} from '../../utils';
import Resources from '../../utils/resources';

import styles from './loadScreen.module.scss';
import { remoteStorage } from '../../utils/remoteStorage';

const LoadScreen: React.FC = () => {
  const { data } = useQuery<{
    progress: { status: number; wasLoaded?: boolean };
  }>(getProgressGql);

  const { data: config } = useQuery<IConfig>(configGql);
  const { isSoundOn } = config!;
  const [isShowContent, setShowContent] = useState(false);
  const { progress } = data!;

  const [getAuth] = useMutation<
    { auth: { sessionId: string } },
    { input: Omit<IAuthInput, 'slotId' | 'lng' | 'home'> }
  >(authGql, {
    onCompleted({ auth: { sessionId } }) {
      const { slotId } = parseQuery<IAuthInput>();
      setSlotConfig({
        ...setSlotConfig(),
        id: slotId,
        sessionId,
      });
      setIsAuthorized(!!data);
    },
  });

  useEffect(() => {
    const getUserBalance = async () => {
      const userBalance = await client.query<{ user: IUserBalance }>({
        query: getUserGql,
        fetchPolicy: 'network-only',
      });
      setCurrency(userBalance.data.user.balance.currency);
    };
    const getLastBet = async () => {
      const betsData = await client.query<{ bets: ISlotHistoryData }>({
        query: slotHistoryGql,
        variables: {
          input: { last: 1, filter: { slotId: setSlotConfig().id } },
        },
        fetchPolicy: 'network-only',
      });
      if (betsData.data.bets.edges[0]) {
        setUserLastBetResult(betsData.data.bets.edges[0].node);
      }
    };
    const getPurchasableBonuses = async () => {
      const bonusData = await client.query<{ bonuses: IBonus[] }>({
        query: getBonuses,
        variables: { input: { purchasable: true, slotId: setSlotConfig().id } },
        fetchPolicy: 'network-only',
      });
      setBonuses(bonusData.data.bonuses);
    };
    const checkBrokenGame = async () => {
      const userBonusData = await client.query<{ userBonuses: UserBonus[] }>({
        query: getUserBonuses,
        variables: {
          input: { status: BonusStatus.ACTIVE, slotId: setSlotConfig().id },
        },
        fetchPolicy: 'network-only',
      });

      if (userBonusData.data.userBonuses.length > 0) {
        setBrokenGame(true);
        setCurrentBonus({
          ...userBonusData.data.userBonuses[0],
          isActive: true,
          currentRound: 0,
          totalRounds: 0,
        });
        setCurrentFreeSpinsTotalWin(0);
        const userBonusBetsData = await client.query<{
          bets: ISlotHistoryData;
        }>({
          query: userBonusBetsGql,

          variables: {
            input: {
              filter: {
                userBonusId: setCurrentBonus().id,
              },
            },
          },
          fetchPolicy: 'network-only',
        });

        if (getLevelByGameMode(getGameModeByBonusId(setCurrentBonus().bonusId)) === 5) {
          setCurrentBonus({
            ...setCurrentBonus(),
            currentRound: 8 - setCurrentBonus().rounds,
            rounds: 8,
          });
        } else {
          setCurrentBonus({
            ...setCurrentBonus(),
            currentRound: userBonusBetsData.data.bets.pageInfo.count,
            rounds: setCurrentBonus().rounds + userBonusBetsData.data.bets.pageInfo.count,
            totalWinAmount: 0,
          });
        }
      }
    };
    setShowContent(true);
    new Loader({ asynchronous: false })
      .stage(20, ELoaderStages.AUTH, async (stage) => {
        setIsMobile(isMobile);
        setIsPortrait(isPortrait(window.innerWidth, window.innerHeight));
        const { token, clientId } = parseQuery<Omit<IAuthInput, 'slotId' | 'lng'>>();
        const { data } = await getAuth({ variables: { input: { token, clientId } } });

        await remoteStorage.init(data?.auth.sessionId as string);
        window.remoteStorage = remoteStorage;
        rebuildStorageCache<IConfig>('config', {
          isLeftHandMode: setIsLeftHandMode,
          isSoundOn: setIsSoundOn,
          isTurboSpin: setIsTurboSpin,
          isMiniPayTable: setIsMiniPayTable,
          isEnabledSpaceSpin: setIsEnabledSpaceSpin,
          isSkipIntroScreen: setSkipIntroScreen,
        });

        setProgress({
          ...setProgress(),
          status: stage,
        });
      })
      .stage(40, ELoaderStages.BONUS_GAME, async (stage) => {
        await getUserBalance();
        await getPurchasableBonuses();
        await getLastBet();
        await checkBrokenGame();

        setProgress({
          ...setProgress(),
          status: stage,
        });
      })
      .stage(60, ELoaderStages.PIXI_ASSETS, async (stage) => {
        await loadPixiAssets(
          [...LOADER_MAPPED_SYMBOLS, ...LOADER_TEXTURES(setIsMobile()), ...SPINE_LOADER_TEXTURES(setIsMobile())], process.env.PUBLIC_URL,
          setIsMobile(),
        );

        setProgress({
          ...setProgress(),
          status: stage,
        });
      })
      .stage(80, ELoaderStages.AUDIO, async (stage) => {
        AudioHowl.initialize(audioSprite, audioSpriteVolume, setSkipIntroScreen(), isSoundOn, setIsSuspended).then(
          () => {
            eventManager.emit(
              EventTypes.SOUND_INITIALIZED,
              AudioHowl.isRestricted && !(!AudioHowl.restrictionChangedOnIntroScreen && !setIsSoundOn()),
            );
          },
        );
      })
      .onError(async (error, resources) => {
        loadErrorHandler(error, resources);
      })
      .onComplete(async () => {
        setProgress({
          ...setProgress(),
          status: 100,
        });
        eventManager.on(EventTypes.POST_RENDER, () => {
          setProgress({
            ...setProgress(),
            wasLoaded: setSkipIntroScreen(),
          });
          setShowContent(false);
        });
      })
      .load();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!isShowContent) return null;
  return (
    <div className={styles.loadScreenWrapper}>
      <div className={styles.logo}>
        <img
          draggable="false"
          alt="logo"
          src={Resources.getSource(ResourceTypes.logo)}
          className={styles.companyLogo}
        />
      </div>
      <ProgressBar
        className={styles.progressBar}
        type="line"
        trailWidth={2}
        trailColor="#000000"
        strokeWidth={2}
        strokeColor="#fcf7cd"
        percent={progress?.status || 0}
      />
    </div>
  );
};

export default LoadScreen;
