import _ from 'lodash';

import { AudioSprite } from '@phoenix7dev/play-music/dist/d';

export enum ISongs {
  BaseGameBGM_Base = '003_01_BGM_BG_Base',
  BaseGameBGM_Melo = '003_02_BGM_BG_Melo',
  BGM_FS1_Loop = '003_03_BGM_FS1_Loop',
  BGM_FS2_Loop = '003_05_BGM_FS2_Loop',
  BGM_FS3_Loop = '003_07_BGM_FS3_Loop',
  BGM_FS4_Loop = '003_09_BGM_FS4_Loop',
  BGM_FS5_Loop = '003_11_BGM_FS5_Loop',
  BigWin_Intro = '003_12_BigWin_Loop_intro',
  BigWin_Loop = '003_12_BigWin_Loop',
  BigWin_End = '003_13_BigWin_End',
  SFX_WIN_BIG = '003_14_Win_Big',
  SFX_WIN_EPIC = '003_15_Win_Mega',
  SFX_WIN_GREAT = '003_16_Win_Great',
  SFX_WIN_MEGA = '003_17_Win_Epic',
  Win_Loop = '003_18_Win_Loop',
  Win_End = '003_19_Win_End',
  FeatureTrigger = '003_20_FeatureTrigger',
  TotalWinBanner = '003_21_TotalWinBanner',
  Scatter_01 = '003_22_Scatter_01',
  Scatter_02 = '003_23_Scatter_02',
  Scatter_03 = '003_24_Scatter_03',
  Scatter_04 = '003_25_Scatter_04',
  Scatter_05 = '003_26_Scatter_05',
  LongSpin = '003_27_LongSpin',
  LvUp = '003_28_LvUp',
  LvUpBanner = '003_29_LvUpBanner',
  LvUpIconSE = '003_30_LvUpIconSE',
  SFX_UI_BetChange = 'SFX_UI_BetChange',
  SFX_UI_Close = 'SFX_UI_Close',
  SFX_UI_General = 'SFX_UI_General',
  SFX_UI_Hover = 'SFX_UI_Hover_EGYPT',
  SFX_UI_MaxBet = 'SFX_UI_MaxBet_EGYPT',
  SFX_UI_MenuOpen = 'SFX_UI_MenuOpen',
  SFX_UI_SpinStart = 'SFX_UI_SpinStart',
  SFX_UI_SpinStop = 'SFX_UI_SpinStop',
}

export const audioSprite: AudioSprite = {
  [ISongs.BaseGameBGM_Base]: [0, 32000, true],
  [ISongs.BaseGameBGM_Melo]: [33000, 32000, true],
  [ISongs.BGM_FS1_Loop]: [66000, 7680.000000000007, true],
  [ISongs.BGM_FS2_Loop]: [75000, 15360, true],
  [ISongs.BGM_FS3_Loop]: [92000, 16000, true],
  [ISongs.BGM_FS4_Loop]: [109000, 13913.061224489795, true],
  [ISongs.BGM_FS5_Loop]: [124000, 114125.89569160997, true],
  [ISongs.BigWin_Loop]: [240000, 23272.789115646276, true],
  [ISongs.BigWin_Intro]: [265000, 2909.115646258499],
  [ISongs.BigWin_End]: [269000, 5433.3786848072805],
  [ISongs.SFX_WIN_BIG]: [276000, 1622.358276643979],
  [ISongs.SFX_WIN_EPIC]: [286000, 1862.2675736961583],
  [ISongs.SFX_WIN_GREAT]: [282000, 2118.412698412726],
  [ISongs.SFX_WIN_MEGA]: [279000, 1696.190476190452],
  [ISongs.Win_Loop]: [289000, 399.77324263037417, true],
  [ISongs.Win_End]: [291000, 933.4693877551103],
  [ISongs.FeatureTrigger]: [293000, 1033.3333333333599],
  [ISongs.TotalWinBanner]: [296000, 4571.473922902498],
  [ISongs.Scatter_01]: [302000, 1899.9999999999773],
  [ISongs.Scatter_02]: [305000, 1900.0453514739206],
  [ISongs.Scatter_03]: [308000, 1900.0453514739206],
  [ISongs.Scatter_04]: [311000, 1900.0453514739206],
  [ISongs.Scatter_05]: [314000, 1900.0453514739206],
  [ISongs.LongSpin]: [317000, 1645.2154195011417],
  [ISongs.LvUp]: [320000, 801.5873015872899],
  [ISongs.LvUpBanner]: [322000, 3600.0453514739092],
  [ISongs.LvUpIconSE]: [327000, 383.1292517006659],
  [ISongs.SFX_UI_BetChange]: [331000, 32.108843537400844],
  [ISongs.SFX_UI_Close]: [333000, 200.68027210885475],
  [ISongs.SFX_UI_General]: [335000, 88.34467120180989],
  [ISongs.SFX_UI_Hover]: [337000, 151.79138321997243],
  [ISongs.SFX_UI_MaxBet]: [339000, 122.94784580501528],
  [ISongs.SFX_UI_MenuOpen]: [341000, 277.1428571428487],
  [ISongs.SFX_UI_SpinStart]: [343000, 249.61451247168043],
  [ISongs.SFX_UI_SpinStop]: [345000, 151.02040816327644],
};

export const audioSpriteVolume: Record<ISongs, number> = {
  [ISongs.BaseGameBGM_Base]: 0.3,
  [ISongs.BaseGameBGM_Melo]: 0.3,
  [ISongs.BGM_FS1_Loop]: 0.3,
  [ISongs.BGM_FS2_Loop]: 0.3,
  [ISongs.BGM_FS3_Loop]: 0.3,
  [ISongs.BGM_FS4_Loop]: 0.3,
  [ISongs.BGM_FS5_Loop]: 0.3,
  [ISongs.BigWin_Intro]: 0.3,
  [ISongs.BigWin_Loop]: 0.3,
  [ISongs.BigWin_End]: 0.3,
  [ISongs.SFX_WIN_BIG]: 0.3,
  [ISongs.SFX_WIN_EPIC]: 0.3,
  [ISongs.SFX_WIN_GREAT]: 0.3,
  [ISongs.SFX_WIN_MEGA]: 0.3,
  [ISongs.Win_Loop]: 0.3,
  [ISongs.Win_End]: 0.3,
  [ISongs.FeatureTrigger]: 0.3,
  [ISongs.TotalWinBanner]: 0.3,
  [ISongs.Scatter_01]: 0.3,
  [ISongs.Scatter_02]: 0.3,
  [ISongs.Scatter_03]: 0.3,
  [ISongs.Scatter_04]: 0.3,
  [ISongs.Scatter_05]: 0.3,
  [ISongs.LongSpin]: 0.3,
  [ISongs.LvUp]: 0.3,
  [ISongs.LvUpBanner]: 0.3,
  [ISongs.LvUpIconSE]: 0.3,
  [ISongs.SFX_UI_BetChange]: 0.4,
  [ISongs.SFX_UI_Close]: 0.4,
  [ISongs.SFX_UI_General]: 0.4,
  [ISongs.SFX_UI_Hover]: 0.4,
  [ISongs.SFX_UI_MaxBet]: 0.4,
  [ISongs.SFX_UI_MenuOpen]: 0.4,
  [ISongs.SFX_UI_SpinStart]: 0.4,
  [ISongs.SFX_UI_SpinStop]: 0.4,
};

export const audioSpriteGaps: { [key in ISongs]?: number } = {
  // [ISongs.Music_BigWin_End]: 2000,
};

export const mappedAudioSprites = _.reduce(
  audioSprite,
  (acc, value, key) => {
    _.set(acc, key, {
      start: value[0],
      duration: value[1] - (audioSpriteGaps[key as ISongs] || 0),
      loop: !!value[2],
    });
    return acc;
  },
  {},
) as {
  [key in ISongs]: { start: number; duration: number; loop: boolean };
};
