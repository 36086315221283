const font = 'NotoSans-SemiCondensedBold';

export const titleTextStyle = {
  fontSize: 80,
  fontFamily: 'NotoSans-SemiCondensedBold',
  align: 'center',
  fill: '0xFFFFFF',
  letterSpacing: 0,
  fontWeight: 'bolder',
  whiteSpace: 'normal',
  fontStyle: 'italic',
  fontVariant: 'normal',
  lineJoin: 'round',
};

export const descriptionTextStyle = {
  fontSize: 75,
  fontFamily: font,
  whiteSpace: 'normal',
  fill: '0xFFFFFF',
  align: 'center',
  fontStyle: 'italic',
  fontVariant: 'normal',
  lineJoin: 'round',
  padding: 10,
};

export const btnTextStyle = {
  fontSize: 100,
  fontFamily: font,
  fill: '0xFFFFFF',
  letterSpacing: 0,
  fontWeight: 'bolder',
  align: 'center',
  whiteSpace: 'normal',
  fontStyle: 'italic',
  fontVariant: 'normal',
  lineJoin: 'round',
};

export const subtitleTextStyle = {
  fontSize: 130,
  fontFamily: font,
  dropShadowColor: '#001f24',
  dropShadowDistance: 8,
  fill: ['#fdff6b', '#ea931a', '#421d00', '#8a5e00'],
  fillGradientStops: [0.2, 0.31, 0.7, 0.9],
  dropShadowBlur: 0,
  dropShadow: true,
  dropShadowAlpha: 0.48,
  dropShadowAngle: 1.7,
  align: 'center',
  fontWeight: 'bolder',
  whiteSpace: 'normal',
  fontStyle: 'italic',
  fontVariant: 'normal',
  lineJoin: 'round',
};

export const freeSpinsTitleLevelStyles = {
  fontSize: 80,
  fontFamily: font,
  fill: '0xFFFFFF',
  letterSpacing: 0,
  fontWeight: 'bolder',
  align: 'center',
  whiteSpace: 'normal',
  fontStyle: 'italic',
  fontVariant: 'normal',
  lineJoin: 'round',
};
export const freeSpinsRetriggerDescriptionStyles = {
  dropShadow: true,
  dropShadowAlpha: 0.48,
  dropShadowAngle: 1.7,
  dropShadowBlur: 0,
  dropShadowColor: '#001f24',
  dropShadowDistance: 8,
  fill: 'white',
  fillGradientStops: [0.2, 0.31, 0.7, 0.9],
  fontFamily: font,
  fontSize: 50,
  fontStyle: 'italic',
  fontWeight: 'bolder',
  whiteSpace: 'pre-line',
  lineJoin: 'round',
};

export const freeSpinsTotalWinTitleStyles = {
  fontSize: 95,
  fontFamily: font,
  fill: '0xFFFFFF',
  letterSpacing: 0,
  fontWeight: 'bolder',
  align: 'center',
  whiteSpace: 'normal',
  fontStyle: 'italic',
  fontVariant: 'normal',
  lineJoin: 'round',
};

export const winTotalSpinsTextStyles = {
  fontSize: 120,
  fontFamily: font,
  fill: ['#f9fb32', '#fea408', '#a36403', '#c4892a'],
  fillGradientStops: [0, 0.31, 0.68, 1],
  letterSpacing: 0,
  align: 'center',
  fontWeight: 'bolder',
  whiteSpace: 'normal',
  fontStyle: 'italic',
  fontVariant: 'normal',
  dropShadow: true,
  dropShadowAlpha: 0.48,
  dropShadowBlur: 0,
  dropShadowColor: '#18484f',
  lineJoin: 'round',
};

export const freeSpinsTotalSpinsStyles = {
  fontSize: 80,
  fontFamily: font,
  fill: '0xFFFFFF',
  letterSpacing: 0,
  fontWeight: 'bolder',
  align: 'center',
  whiteSpace: 'normal',
  fontStyle: 'italic',
  fontVariant: 'normal',
  lineJoin: 'round',
};
