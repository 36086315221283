import AudioHowl from '@phoenix7dev/play-music';
import i18n from 'i18next';
import _ from 'lodash';
import * as PIXI from 'pixi.js';

import { ISongs, mappedAudioSprites } from '../config';
import {
  BetBonusReward,
  EventTypes,
  GameMode,
  ISettledBet,
  reelSets,
  UserBonus,
} from '../global.d';
import {
  client,
  getUserBonuses,
  isStoppedGql,
  setBrokenGame,
  setCurrency,
  setCurrentBonus,
  setCurrentFreeSpinsTotalWin,
  setFreeSpinsTotalWin,
  setGameMode,
  setIsContinueAutoSpinsAfterFeature,
  setIsDuringBigWinLoop,
  setIsFreeSpinsWin,
  setIsRevokeThrowingError,
  setLastRegularWinAmount,
  setNextResult,
  setPrevReelsPosition,
  setProgress,
  setReelSetId,
  setSlotConfig,
  setStressful,
  setUserLastBetResult,
  setWinAmount,
  slotBetGql,
} from '../gql';
import {
  formatNumber,
  getGameModeByBonusId,
  getGameModeByReelSetId,
  getLevelByGameMode,
  getSpinResult4X5,
  isFreeSpinsMode,
  isScatter,
  normalizeCoins,
  showCurrency,
} from '../utils';
import Animation from './animations/animation';
import AnimationChain from './animations/animationChain';
import AnimationGroup from './animations/animationGroup';
import Animator from './animations/animator';
import Tween from './animations/tween';
import Background from './background/background';
import BottomContainer from './bottomContainer/bottomContainer';
import AutoplayBtn from './button/autoplayBtn';
import BetBtn from './button/betBtn';
import MenuBtn from './button/menuBtn';
import SoundBtn from './button/soundBtn';
import SpinBtn from './button/spinBtn';
import TurboSpinBtn from './button/turboSpinBtn';
import {
  ANTICIPATION_ENABLE,
  ANTICIPATION_END_SYMBOLS_AMOUNT,
  ANTICIPATION_START_SYMBOLS_AMOUNT,
  ANTICIPATION_SYMBOLS_ID,
  eventManager,
  FREE_SPINS_LVL_UP_CHANGES_DELAY,
  FREE_SPINS_TIME_OUT_BANNER,
  FREE_SPINS_TIME_OUT_RETRIGGER_BANNER,
  REELS_AMOUNT,
  SlotMachineState,
  SLOTS_PER_REEL_AMOUNT,
} from './config';
import { Icon, ISlotData } from './d';
import FadeArea from './fadeArea/fadeArea';
import GameView from './gameView/gameView';
import MiniPayTableContainer from './miniPayTable/miniPayTableContainer';
import ReelsBackgroundContainer from './reels/background/reelsBackground';
import ReelsContainer from './reels/reelsContainer';
import SafeArea from './safeArea/safeArea';
import { Slot } from './slot/slot';
import SpinAnimation from './spin/spin';
import TintContainer from './tint/tintContainer';
import { SlotsStopDisplayContainer } from './winAnimations/slotsStopDisplayContainer';
import WinCountUpMessage from './winAnimations/winCountUpMessage';
import WinLabelContainer from './winAnimations/winLabelContainer';
import WinSlotsContainer from './winAnimations/winSlotsContainer';

class SlotMachine {
  private readonly application: PIXI.Application;

  private slotConfig: ISlotData;

  public isStopped = false;

  public isReadyForStop = false;

  public nextResult: ISettledBet | null = null;

  public stopCallback: (() => void) | null = null;

  public animator: Animator;

  private introSoundDelayAnimation: Animation | undefined;

  private static slotMachine: SlotMachine;

  private isSpinInProgressCallback: () => void;

  private isSlotBusyCallback: () => void;

  public static initSlotMachine = (
    slotData: ISlotData,
    isSpinInProgressCallback: () => void,
    isSlotBusyCallback: () => void,
    application: PIXI.Application,
  ): void => {
    SlotMachine.slotMachine = new SlotMachine(
      slotData,
      isSpinInProgressCallback,
      isSlotBusyCallback,
      application,
    );
  };

  public static getInstance = (): SlotMachine => SlotMachine.slotMachine;

  public winCountUpMessage: WinCountUpMessage;

  public reelsBackgroundContainer: ReelsBackgroundContainer;

  public reelsContainer: ReelsContainer;

  public tintContainer: TintContainer;

  public miniPayTableContainer: MiniPayTableContainer;

  public winSlotsContainer: WinSlotsContainer;

  public slotStopDisplayContainer: SlotsStopDisplayContainer;

  public gameView: GameView;

  public winLabelContainer: WinLabelContainer;

  public safeArea: SafeArea;

  public fadeArea: FadeArea;

  public background: Background;

  public bottom: BottomContainer;

  public state: SlotMachineState = SlotMachineState.IDLE;

  public menuBtn: MenuBtn;

  public soundBtn: SoundBtn;

  public turboSpinBtn: TurboSpinBtn;

  public spinBtn: SpinBtn;

  public betBtn: BetBtn;

  public autoplayBtn: AutoplayBtn;

  private constructor(
    slotData: ISlotData,
    isSpinInProgressCallback: () => void,
    isSlotBusyCallback: () => void,
    application: PIXI.Application,
  ) {
    this.application = application;
    this.application.renderer.once(EventTypes.POST_RENDER, () => {
      eventManager.emit(EventTypes.POST_RENDER);
    });
    this.initListeners();
    this.isSpinInProgressCallback = isSpinInProgressCallback;
    this.isSlotBusyCallback = isSlotBusyCallback;
    this.animator = new Animator(this.application);
    this.slotConfig = slotData;
    this.reelsBackgroundContainer = new ReelsBackgroundContainer();
    // todo add if bonus logic

    const startPosition = setUserLastBetResult().id
      ? setUserLastBetResult().result.reelPositions
      : slotData.settings.startPosition;
    setPrevReelsPosition(startPosition);
    const reelSet = setUserLastBetResult().id
      ? slotData.reels.find(
          (reelSet) => reelSet.id === setUserLastBetResult().reelSetId,
        )!
      : slotData.reels[0];
    setReelSetId(reelSet.id);
    this.reelsContainer = new ReelsContainer(reelSet.layout, startPosition);
    this.tintContainer = new TintContainer();
    this.winSlotsContainer = new WinSlotsContainer();
    const spinResult = getSpinResult4X5({
      reelPositions: startPosition.slice(0, 5),
      reelSet,
      icons: slotData.icons,
    });
    this.slotStopDisplayContainer = new SlotsStopDisplayContainer(spinResult);
    eventManager.emit(EventTypes.SHOW_STOP_SLOTS_DISPLAY, spinResult);

    this.miniPayTableContainer = new MiniPayTableContainer(
      slotData.icons,
      this.getSlotById.bind(this),
    );
    this.miniPayTableContainer.setSpinResult(spinResult);

    this.background = new Background();
    this.application.stage.addChild(this.background);

    this.menuBtn = new MenuBtn();
    this.soundBtn = new SoundBtn();
    this.turboSpinBtn = new TurboSpinBtn();
    this.spinBtn = new SpinBtn();
    this.betBtn = new BetBtn();
    this.autoplayBtn = new AutoplayBtn();

    this.bottom = new BottomContainer();
    this.application.stage.addChild(this.bottom);

    this.safeArea = new SafeArea();
    this.winLabelContainer = new WinLabelContainer();
    this.winCountUpMessage = new WinCountUpMessage();
    this.gameView = new GameView({
      slotStopDisplayContainer: this.slotStopDisplayContainer,
      winSlotsContainer: this.winSlotsContainer,
      reelsBackgroundContainer: this.reelsBackgroundContainer,
      reelsContainer: this.reelsContainer,
      tintContainer: this.tintContainer,
      winLabelContainer: this.winLabelContainer,
      winCountUpMessage: this.winCountUpMessage,
      miniPayTableContainer: this.miniPayTableContainer,
    });
    this.gameView.interactive = true;
    this.gameView.on('mousedown', () => {
      this.skipAnimations();
    });
    this.gameView.on('touchstart', () => {
      this.skipAnimations();
    });
    this.safeArea.addChild(this.gameView);
    this.application.stage.addChild(this.safeArea);
    this.fadeArea = new FadeArea();
    this.application.stage.addChild(this.menuBtn);
    this.application.stage.addChild(this.soundBtn);
    this.application.stage.addChild(this.turboSpinBtn);
    this.application.stage.addChild(this.spinBtn);
    this.application.stage.addChild(this.betBtn);
    this.application.stage.addChild(this.autoplayBtn);
    this.application.stage.addChild(this.fadeArea);
    if (setBrokenGame()) {
      this.onBrokenGame();
    }
  }

  private async onBrokenGame(): Promise<void> {
    const bet = await client.query<ISettledBet>({
      query: slotBetGql,
      variables: { input: { id: setCurrentBonus().betId } },
      fetchPolicy: 'network-only',
    });
    setCurrentFreeSpinsTotalWin(0);
    const gameMode = getGameModeByBonusId(setCurrentBonus().bonusId);
    setIsFreeSpinsWin(true);
    setGameMode(gameMode);
    setReelSetId(setCurrentBonus().reelSetId);
    eventManager.emit(EventTypes.MANUAL_CHANGE_BACKGROUND, {
      mode: gameMode,
    });

    eventManager.emit(EventTypes.HIDE_WIN_LABEL);
    eventManager.emit(
      EventTypes.UPDATE_TOTAL_WIN_VALUE,
      setCurrentFreeSpinsTotalWin(),
    );
    eventManager.emit(EventTypes.CREATE_FREE_SPINS_TITLE, {
      text: 'freeSpinsTitleText',
      spins: setCurrentBonus().rounds,
      currentSpin: setCurrentBonus().currentRound,
    });
    if (setProgress().wasLoaded) {
      this.setState(SlotMachineState.IDLE);
    } else {
      eventManager.once(EventTypes.HANDLE_DESTROY_INTRO_SCREEN, () => {
        this.setState(SlotMachineState.IDLE);
      });
    }
  }

  private initListeners(): void {
    eventManager.addListener(EventTypes.RESIZE, this.resize.bind(this));
    eventManager.addListener(
      EventTypes.RESET_SLOT_MACHINE,
      this.resetSlotMachine.bind(this),
    );
    eventManager.addListener(
      EventTypes.SLOT_MACHINE_STATE_CHANGE,
      this.onStateChange.bind(this),
    );
    eventManager.addListener(
      EventTypes.REGISTER_ANIMATOR,
      this.registerAnimator.bind(this),
    );
    eventManager.addListener(
      EventTypes.REMOVE_ANIMATOR,
      this.removeAnimator.bind(this),
    );
    eventManager.addListener(
      EventTypes.REELS_STOPPED,
      this.onReelsStopped.bind(this),
    );
    eventManager.addListener(
      EventTypes.COUNT_UP_END,
      this.onCountUpEnd.bind(this),
    );
    eventManager.addListener(
      EventTypes.THROW_ERROR,
      this.handleError.bind(this),
    );
    eventManager.addListener(
      EventTypes.END_RETRIGGER_FEATURE,
      this.onRetriggerEnd.bind(this),
    );
    eventManager.addListener(
      EventTypes.CHANGE_MODE,
      this.onChangeMode.bind(this),
    );
    eventManager.addListener(EventTypes.HANDLE_CHANGE_RESTRICTION, () => {
      this.handleAudioOnFreeSpinLevelStart(
        getLevelByGameMode(setGameMode()),
        0,
      );
      if (setIsDuringBigWinLoop()) {
        AudioHowl.play({ type: ISongs.BigWin_Loop });

        const level = getLevelByGameMode(setGameMode());
        AudioHowl.fadeOut(
          0,
          ISongs[`BGM_FS${level}_Loop` as keyof typeof ISongs],
          0,
        );
      }
    });
  }

  private resetSlotMachine(): void {
    eventManager.emit(EventTypes.ROLLBACK_REELS, setPrevReelsPosition());
    this.setState(SlotMachineState.IDLE);
    this.isSpinInProgressCallback();
  }

  public throwTimeoutError(): void {
    eventManager.emit(EventTypes.BREAK_SPIN_ANIMATION);
    eventManager.emit(EventTypes.THROW_ERROR);
  }

  private handleAudioOnFreeSpinEnd(level: number): void {
    AudioHowl.stop({
      type: ISongs[`BGM_FS${level}_Loop` as keyof typeof ISongs],
    });
    AudioHowl.play({ type: ISongs.BaseGameBGM_Base });
    AudioHowl.play({ type: ISongs.BaseGameBGM_Melo, volume: 0 });
  }

  public handleAudioOnFreeSpinLevelStart(
    level: number,
    prevLevel: number,
  ): void {
    if (prevLevel === level) {
      AudioHowl.fadeOut(
        100,
        ISongs[`BGM_FS${level}_Loop` as keyof typeof ISongs],
      );
      AudioHowl.fadeIn(
        3000,
        ISongs[`BGM_FS${level}_Loop` as keyof typeof ISongs],
      );
      return;
    }
    if (prevLevel === GameMode.REGULAR) {
      AudioHowl.stop({ type: ISongs.BaseGameBGM_Base });
      AudioHowl.stop({ type: ISongs.BaseGameBGM_Melo });
    } else {
      AudioHowl.stop({
        type: ISongs[`BGM_FS${prevLevel}_Loop` as keyof typeof ISongs],
      });
    }
    AudioHowl.play({
      type: ISongs[`BGM_FS${level}_Loop` as keyof typeof ISongs],
      stopPrev: true,
      volume: 0,
    });
    AudioHowl.fadeIn(
      3000,
      ISongs[`BGM_FS${level}_Loop` as keyof typeof ISongs],
    );
  }

  private onChangeMode(settings: {
    mode: GameMode;
    reelPositions: number[];
    reelSetId: string;
    isRetrigger?: boolean;
  }) {
    const previousGameMode: number = getLevelByGameMode(setGameMode());
    const currentGameMode = settings.mode;
    if (previousGameMode !== currentGameMode) {
      setGameMode(settings.mode);
      setReelSetId(settings.reelSetId);
      const reelSet = setSlotConfig().reels.find(
        (reels) => reels.id === settings.reelSetId,
      );
      const spinResult = getSpinResult4X5({
        reelPositions: settings.reelPositions.slice(0, 5),
        reelSet: reelSet!,
        icons: setSlotConfig().icons,
      });
      this.miniPayTableContainer.setSpinResult(spinResult);
      console.log('settings', settings);
      console.log(
        setSlotConfig().reels,
        settings.reelPositions,
        settings.reelSetId,
      );
      // eslint-disable-next-line no-debugger
      debugger;

      eventManager.emit(EventTypes.CHANGE_REEL_SET, {
        reelSet: setSlotConfig().reels.find(
          (reels) => reels.id === settings.reelSetId,
        ),
        reelPositions: settings.reelPositions,
      });
      eventManager.emit(EventTypes.SHOW_STOP_SLOTS_DISPLAY, spinResult);
    }
    eventManager.emit(EventTypes.SKIP_WIN_COUNT_UP_ANIMATION);
    eventManager.emit(EventTypes.SKIP_WIN_SLOTS_ANIMATION);
    if (settings.mode === GameMode.REGULAR) {
      setIsFreeSpinsWin(false);
      setCurrentBonus({
        ...setCurrentBonus(),
        isActive: false,
        totalRounds: 0,
      });
      eventManager.emit(
        EventTypes.UPDATE_USER_BALANCE,
        this.nextResult?.balance.settled,
      );
      eventManager.emit(
        EventTypes.UPDATE_WIN_VALUE,
        formatNumber(
          setCurrency(),
          normalizeCoins(setFreeSpinsTotalWin()),
          showCurrency(setCurrency()),
        ),
      );
      eventManager.emit(EventTypes.REMOVE_FREE_SPINS_TITLE);
      this.setState(SlotMachineState.IDLE);
      this.introSoundDelayAnimation?.skip();
      this.handleAudioOnFreeSpinEnd(previousGameMode);
    } else if (isFreeSpinsMode(settings.mode)) {
      const bonus = this.getBonusFromResult();

      // todo replace with normal error
      if (!bonus) throw new Error('Something went wrong');
      eventManager.emit(
        EventTypes.UPDATE_TOTAL_WIN_VALUE,
        setCurrentFreeSpinsTotalWin(),
      );
      if (settings.isRetrigger) {
        setCurrentBonus({
          ...bonus,
          totalRounds: setCurrentBonus().totalRounds,
        });
        if (previousGameMode !== settings.mode) {
          const chain = new AnimationChain();
          const levelUpChangesDelay = Tween.createDelayAnimation(
            FREE_SPINS_LVL_UP_CHANGES_DELAY,
          );
          const levelUpChangesUpdate = () => {
            AudioHowl.play({ type: ISongs.LvUpIconSE });
            eventManager.emit(EventTypes.SET_NEXT_FREE_SPINS_LEVEL, {
              mode: currentGameMode,
            });
            eventManager.emit(
              EventTypes.HANDLE_UPDATE_FREE_SPINS_TITLE,
              bonus.rounds,
              '0',
              true,
            );
            AudioHowl.play({ type: ISongs.LvUpIconSE });
          };
          levelUpChangesDelay.addOnComplete(levelUpChangesUpdate);
          levelUpChangesDelay.addOnSkip(levelUpChangesUpdate);
          const retriggerBannerTime = Tween.createDelayAnimation(
            FREE_SPINS_TIME_OUT_RETRIGGER_BANNER,
          );
          chain.appendAnimation(levelUpChangesDelay);
          chain.appendAnimation(retriggerBannerTime);
          const callback = () => {
            setCurrentBonus({
              ...bonus,
              totalRounds: setCurrentBonus().totalRounds,
              isActive: true,
              currentRound: 0,
            });
            this.setState(SlotMachineState.IDLE);
          };
          chain.addOnComplete(() => {
            eventManager.emit(EventTypes.MANUAL_DESTROY_MESSAGE_BANNER);
            callback();
          });
          chain.addOnSkip(() => {
            eventManager.emit(EventTypes.MANUAL_DESTROY_MESSAGE_BANNER);
            callback();
          });
          chain.start();
          eventManager.emit(EventTypes.CREATE_RETRIGGER_MESSAGE_BANNER, {
            title: i18n.t('freeSpinsTitle', { currentGameMode }),
            titleLevel: i18n.t('freeSpinsLevel', { currentGameMode }),
            subTitle: i18n.t('freeSpinsText'),
            freeSpinsSubtitle: i18n.t('freeSpinsSubtitle'),
            btnText: i18n.t('startText'),
            level: getLevelByGameMode(settings.mode),
            callback: () => chain.skip(),
          });
        } else {
          eventManager.emit(
            EventTypes.HANDLE_UPDATE_FREE_SPINS_TITLE,
            bonus.rounds,
            '0',
            true,
          );
          AudioHowl.play({ type: ISongs.LvUpIconSE });
          setCurrentBonus({
            ...bonus,
            isActive: true,
            currentRound: 0,
            totalRounds: setCurrentBonus().totalRounds,
          });
          this.setState(SlotMachineState.IDLE);
        }
      } else {
        setCurrentBonus({ ...bonus, totalRounds: 0 });
        eventManager.emit(EventTypes.REMOVE_FREE_SPINS_TITLE);
        eventManager.emit(EventTypes.CREATE_FREE_SPINS_TITLE, {
          text: 'freeSpinsTitleText',
          spins: bonus.rounds,
          currentSpin: '0',
        });

        if (!setIsContinueAutoSpinsAfterFeature()) {
          eventManager.emit(EventTypes.CREATE_MESSAGE_BANNER, {
            title: i18n.t('freeSpinsTitle', { currentGameMode }),
            titleLevel: i18n.t('freeSpinsLevel', { currentGameMode }),
            subTitle: i18n.t('freeSpinsText'),
            freeSpinsSubtitle: i18n.t('freeSpinsSubtitle'),
            btnText: i18n.t('startText'),
            callback: () => {
              setCurrentBonus({
                ...bonus,
                isActive: true,
                totalRounds: setCurrentBonus().totalRounds,
                currentRound: 0,
              });
              this.setState(SlotMachineState.IDLE);
            },
          });
        } else {
          setCurrentBonus({
            ...bonus,
            isActive: true,
            totalRounds: 0,
            currentRound: 0,
          });
          this.setState(SlotMachineState.IDLE);
        }
      }
      this.handleAudioOnFreeSpinLevelStart(currentGameMode, previousGameMode);
    }
  }

  private startFreeSpins(): void {
    setIsFreeSpinsWin(true);
    eventManager.emit(EventTypes.START_MODE_CHANGE_FADE, {
      mode: GameMode.FREE_SPINS_LVL1,
      reelPositions: this.nextResult?.bet.result.reelPositions,
      reelSetId: reelSets[GameMode.FREE_SPINS_LVL1],
    });
  }

  private async endFreeSpins(): Promise<void> {
    const res = await client.query<{
      userBonuses: UserBonus[];
    }>({
      query: getUserBonuses,
      variables: { input: { id: setCurrentBonus().id } },
      fetchPolicy: 'network-only',
    });
    const bonus = res.data.userBonuses[0];
    const { betId } = res.data.userBonuses[0];
    const bet = await client.query<ISettledBet>({
      query: slotBetGql,
      variables: { input: { id: betId } },
      fetchPolicy: 'network-only',
    });

    const { reelPositions, winCountAmount, reelSetId } = {
      reelPositions: bet.data.bet.result.reelPositions,
      winCountAmount: bet.data.bet.result.winCoinAmount,
      reelSetId: bet.data.bet.reelSetId,
    };

    // TODO: handling for LEVEL 2 or higher
    // setFreeSpinsTotalWin(bonus.totalWinAmount + winCountAmount);
    AudioHowl.play({ type: ISongs.TotalWinBanner, stopPrev: true });
    setFreeSpinsTotalWin(setCurrentFreeSpinsTotalWin());
    setLastRegularWinAmount(setFreeSpinsTotalWin());
    const callback = () => {
      eventManager.emit(EventTypes.START_MODE_CHANGE_FADE, {
        mode: GameMode.REGULAR,
        reelSetId,
        reelPositions,
      });
    };
    eventManager.emit(EventTypes.SET_EPIC_WIN_VISIBILITY, false);
    eventManager.emit(EventTypes.SET_BIG_WIN_VISIBILITY, false);
    eventManager.emit(EventTypes.SET_MEGA_WIN_VISIBILITY, false);
    eventManager.emit(EventTypes.SET_GREAT_WIN_VISIBILITY, false);
    eventManager.emit(EventTypes.HIDE_WIN_COUNT_UP_MESSAGE);
    this.skipAnimations();
    const delay = Tween.createDelayAnimation(FREE_SPINS_TIME_OUT_BANNER);
    delay.addOnComplete(() => {
      callback();
    });
    if (!setIsContinueAutoSpinsAfterFeature()) {
      eventManager.emit(EventTypes.CREATE_WIN_MESSAGE_BANNER, {
        winTitle: i18n.t('freeSpinsTotalWinTitle'),
        totalWin: `${formatNumber(
          setCurrency(),
          normalizeCoins(setFreeSpinsTotalWin()),
          showCurrency(setCurrency()),
        )} `,
        totalSpins: setCurrentBonus().totalRounds,
        level: getLevelByGameMode(setGameMode()),
        winTotalSpins: i18n.t('freeSpinsTotalSpins'),
        preventDefaultDestroy: true,
        callback,
      });
    } else {
      eventManager.emit(EventTypes.CREATE_WIN_MESSAGE_BANNER, {
        inTitle: i18n.t('freeSpinsTotalWinTitle'),
        totalWin: `${formatNumber(
          setCurrency(),
          normalizeCoins(setFreeSpinsTotalWin()),
          showCurrency(setCurrency()),
        )}`,
        totalSpins: setCurrentBonus().totalRounds,
        level: getLevelByGameMode(setGameMode()),
        winTotalSpins: i18n.t('freeSpinsTotalSpins'),
        preventDefaultDestroy: true,
        onInitCallback: () => delay.start(),
      });
    }
    setBrokenGame(false);
  }

  private handleError(): void {
    if (!setIsRevokeThrowingError()) {
      setStressful({
        show: true,
        type: 'network',
        message: i18n.t('error_general'),
      });
    }
  }

  private registerAnimator(animator: () => void, priority?: number) {
    if (priority !== undefined) {
      this.application.ticker.add(animator, undefined, priority);
    } else {
      this.application.ticker.add(animator);
    }
  }

  private removeAnimator(animator: () => void) {
    this.application.ticker.remove(animator);
  }

  private removeErrorHandler(): void {
    this.reelsContainer.reels[0].spinAnimation
      ?.getFakeRolling()
      .removeOnComplete(this.throwTimeoutError);
  }

  private updateFreeSpinsAmount(total: number, current: number): void {
    eventManager.emit(
      EventTypes.HANDLE_UPDATE_FREE_SPINS_TITLE,
      current,
      total,
      false,
    );
  }

  public spin(isTurboSpin: boolean | undefined): void {
    this.isReadyForStop = false;
    this.reelsContainer.forcedStop = false;
    if (this.state === SlotMachineState.SPIN) {
      this.isStopped = true;
      if (this.nextResult) {
        if (isFreeSpinsMode(setGameMode())) {
          this.updateFreeSpinsAmount(
            setCurrentBonus().currentRound,
            setCurrentBonus().rounds,
          );
        }
        this.removeErrorHandler();
        this.dynamicReelSetChange();
        eventManager.emit(
          EventTypes.SETUP_REEL_POSITIONS,
          this.nextResult.bet.result.reelPositions,
          this.getScatterCount(this.nextResult.bet.result.spinResult),
          this.getAnticipationStartReelId(
            this.nextResult.bet.result.spinResult,
          ),
          this.getAnticipationEndReelId(this.nextResult.bet.result.spinResult),
        );
        this.stopSpin();
      }
      return;
    }
    if (this.state === SlotMachineState.IDLE) {
      eventManager.emit(EventTypes.START_SPIN_ANIMATION);
      this.skipAnimations();
      eventManager.emit(EventTypes.HIDE_STOP_SLOTS_DISPLAY);
      this.isStopped = false;
      this.nextResult = null;
      this.setState(SlotMachineState.SPIN);
      const spinAnimation = this.getSpinAnimation(
        !isFreeSpinsMode(setGameMode()) && !!isTurboSpin,
      );

      if (isFreeSpinsMode(setGameMode())) {
        const bonus = setCurrentBonus();
        bonus.currentRound += 1;
        bonus.totalRounds += 1;
        setCurrentBonus(bonus);
      }
      spinAnimation.start();
    }

    if (this.state === SlotMachineState.WINNING) {
      this.skipAnimations();
    }
  }

  private getSpinAnimation(isTurboSpin: boolean): AnimationGroup {
    const animationGroup = new AnimationGroup();
    for (let i = 0; i < REELS_AMOUNT; i++) {
      const reel = this.reelsContainer.reels[i];
      const spinAnimation: SpinAnimation = reel.createSpinAnimation(
        isTurboSpin,
      );

      if (i === 0) {
        spinAnimation.getFakeRolling().addOnChange(() => {
          if (this.nextResult && !this.isReadyForStop) {
            this.isReadyForStop = true;
            this.removeErrorHandler();
            if (isFreeSpinsMode(setGameMode())) {
              this.updateFreeSpinsAmount(
                setCurrentBonus().currentRound,
                setCurrentBonus().rounds,
              );
            }
            this.dynamicReelSetChange();
            eventManager.emit(
              EventTypes.SETUP_REEL_POSITIONS,
              this.nextResult.bet.result.reelPositions,
              this.getScatterCount(this.nextResult.bet.result.spinResult),
              this.getAnticipationStartReelId(
                this.nextResult.bet.result.spinResult,
              ),
              this.getAnticipationEndReelId(
                this.nextResult.bet.result.spinResult,
              ),
            );
          }
        });
        spinAnimation.getFakeRolling().addOnComplete(this.throwTimeoutError);
      }
      this.reelsContainer.reels[i].isPlaySoundOnStop = true;

      if (!this.nextResult) {
        if (i === REELS_AMOUNT - 1) {
          spinAnimation.addOnComplete(() =>
            eventManager.emit(EventTypes.REELS_STOPPED, isTurboSpin),
          );
        }
      }
      animationGroup.addAnimation(spinAnimation);
    }

    return animationGroup;
  }

  private getBonusFromResult(): UserBonus | undefined {
    return (this.nextResult?.rewards.find(
      // eslint-disable-next-line no-underscore-dangle
      (reward) => reward.__typename === 'BetBonusReward',
    ) as BetBonusReward)?.userBonus;
  }

  private onCountUpEnd(): void {
    const bonus = this.getBonusFromResult();
    const mode = setGameMode();
    if (bonus) {
      if (mode === GameMode.REGULAR) {
        setLastRegularWinAmount(this.nextResult?.bet.result.winCoinAmount);
        setCurrentBonus({
          ...bonus,
          isActive: true,
          currentRound: 0,
          totalRounds: 0,
        });
        setCurrentFreeSpinsTotalWin(this.nextResult!.bet.result.winCoinAmount);
        this.startFreeSpins();
        this.setState(SlotMachineState.IDLE);
      } else {
        const delay = Tween.createDelayAnimation(500);
        delay.addOnStart(() => {
          eventManager.emit(EventTypes.START_RETRIGGER_ANIMATION);

          setCurrentFreeSpinsTotalWin(
            setCurrentFreeSpinsTotalWin() +
              this.nextResult!.bet.result.winCoinAmount,
          );
          eventManager.emit(
            EventTypes.UPDATE_TOTAL_WIN_VALUE,
            setCurrentFreeSpinsTotalWin(),
          );
        });
        delay.addOnComplete(() => {
          this.onRetrigger(bonus);
        });
        delay.start();
      }
    } else {
      if (mode === GameMode.REGULAR) {
        setWinAmount(this.nextResult?.bet.result.winCoinAmount);
        setLastRegularWinAmount(this.nextResult?.bet.result.winCoinAmount);
        eventManager.emit(
          EventTypes.UPDATE_USER_BALANCE,
          this.nextResult?.balance.settled,
        );
      }
      if (isFreeSpinsMode(mode)) {
        setCurrentFreeSpinsTotalWin(
          setCurrentFreeSpinsTotalWin() +
            this.nextResult!.bet.result.winCoinAmount,
        );
        eventManager.emit(
          EventTypes.UPDATE_TOTAL_WIN_VALUE,
          setCurrentFreeSpinsTotalWin(),
        );
      }
      this.setState(SlotMachineState.IDLE);
    }
  }

  private dynamicReelSetChange(): void {
    if (setReelSetId() !== reelSets[setGameMode()]) {
      console.log('setSlotConfig().reels', setSlotConfig().reels);
      console.log('reelSets', reelSets);
      console.log('setGameMode()', setGameMode());
      // eslint-disable-next-line no-debugger
      debugger;
      eventManager.emit(EventTypes.CHANGE_REEL_SET, {
        reelSet: setSlotConfig().reels.find(
          (reels) => reels.id === reelSets[setGameMode()],
        ),
        reelPositions: [0, 0, 0, 0, 0],
      });
      setReelSetId(reelSets[setGameMode()]);
    }
  }

  private onRetrigger(userBonus: UserBonus | undefined): void {
    // todo throw error correctly
    if (userBonus === undefined) throw new Error('Bonus not found');
    eventManager.emit(EventTypes.CHANGE_MODE, {
      mode: getGameModeByReelSetId(userBonus.bonus.reelSetId),
      reelPositions: this.nextResult?.bet.result.reelPositions,
      reelSetId: userBonus.bonus.reelSetId,
      isRetrigger: true,
    });
  }

  private onRetriggerEnd(): void {
    this.updateFreeSpinsAmount(
      setCurrentBonus().currentRound,
      setCurrentBonus().rounds,
    );
    this.setState(SlotMachineState.IDLE);
  }

  private onReelsStopped(isTurboSpin: boolean): void {
    this.onSpinStop(isTurboSpin);
  }

  private getAnticipationEndReelId(spinResult: Array<Icon>): number {
    if (!ANTICIPATION_ENABLE) return REELS_AMOUNT;
    let minReelId = REELS_AMOUNT;
    _.forEach(ANTICIPATION_SYMBOLS_ID, (symbolId, i) => {
      const count = ANTICIPATION_END_SYMBOLS_AMOUNT[i];
      let currentCount = 0;
      for (let j = 0; j < REELS_AMOUNT; j++) {
        for (let i = 0; i < SLOTS_PER_REEL_AMOUNT; i++) {
          // eslint-disable-next-line no-plusplus
          if (spinResult[j + REELS_AMOUNT * i].id === symbolId) currentCount++;
        }
        if (currentCount >= count) minReelId = Math.min(minReelId, j);
      }
    });
    return minReelId + 1;
  }

  private getAnticipationStartReelId(spinResult: Array<Icon>): number {
    if (!ANTICIPATION_ENABLE) return REELS_AMOUNT;
    let minReelId = REELS_AMOUNT;
    _.forEach(ANTICIPATION_SYMBOLS_ID, (symbolId, i) => {
      const count = ANTICIPATION_START_SYMBOLS_AMOUNT[i];
      let currentCount = 0;
      for (let j = 0; j < REELS_AMOUNT; j++) {
        for (let i = 0; i < SLOTS_PER_REEL_AMOUNT; i++) {
          // eslint-disable-next-line no-plusplus
          if (spinResult[j + REELS_AMOUNT * i].id === symbolId) currentCount++;
        }

        if (currentCount >= count) minReelId = Math.min(minReelId, j);
      }
    });
    return minReelId;
  }

  private getScatterCount(spinResult: Array<Icon>): Array<number> {
    let count = 0;
    return _(spinResult)
      .chunk(REELS_AMOUNT)
      .unzip()
      .map((col) => {
        if (col.some((icon) => isScatter(icon.id))) {
          count += 1;
          return count;
        }
        return 0;
      })
      .value();
  }

  private skipAnimations(): void {
    eventManager.emit(EventTypes.SKIP_WIN_COUNT_UP_ANIMATION);
    if (this.state === SlotMachineState.IDLE) {
      eventManager.emit(EventTypes.SKIP_WIN_SLOTS_ANIMATION);
    }
  }

  public setResult(result: ISettledBet): void {
    const spinResult = getSpinResult4X5({
      reelPositions: result.bet.result.reelPositions.slice(0, 5),
      reelSet: setSlotConfig().reels.find(
        (reelSet) => reelSet.id === result.bet.reelSet.id,
      )!,
      icons: setSlotConfig().icons,
    });
    result.bet.result.spinResult = spinResult;
    this.nextResult = result;
    setPrevReelsPosition(result.bet.result.reelPositions);
    setNextResult(result);
    this.miniPayTableContainer.setSpinResult(
      this.nextResult!.bet.result.spinResult,
    );
    if (!isFreeSpinsMode(setGameMode())) {
      eventManager.emit(
        EventTypes.UPDATE_USER_BALANCE,
        this.nextResult.balance.placed,
      );
    }
  }

  public onSpinStop(isTurboSpin: boolean | undefined): void {
    this.isSpinInProgressCallback();
    this.setState(SlotMachineState.JINGLE);
  }

  public setStopCallback(fn: () => void): void {
    this.stopCallback = fn;
  }

  public stopSpin(): void {
    eventManager.emit(EventTypes.FORCE_STOP_REELS);
    this.setState(SlotMachineState.STOP);
  }

  public getSlotAt(x: number, y: number): Slot | null {
    return this.reelsContainer.reels[x].slots[
      (2 * this.reelsContainer.reels[x].data.length -
        this.reelsContainer.reels[x].position +
        y -
        1) %
        this.reelsContainer.reels[x].data.length
    ];
  }

  public getSlotById(id: number): Slot | null {
    return this.getSlotAt(id % REELS_AMOUNT, Math.floor(id / REELS_AMOUNT));
  }

  public getApplication(): PIXI.Application {
    return this.application;
  }

  private resize(width: number, height: number): void {
    this.application.renderer.resize(width, height);
  }

  private setState(state: SlotMachineState): void {
    this.state = state;
    eventManager.emit(
      EventTypes.DISABLE_PAY_TABLE,
      isFreeSpinsMode(setGameMode()) ? false : state === 0,
    );
    eventManager.emit(EventTypes.SLOT_MACHINE_STATE_CHANGE, state);
  }

  private hasWin() {
    return this.nextResult!.bet.result.winCoinAmount > 0;
  }

  private onStateChange(state: SlotMachineState): void {
    if (state === SlotMachineState.IDLE) {
      this.isSlotBusyCallback();
      if (this.stopCallback) {
        this.stopCallback();
        this.stopCallback = null;
      }
      if (isFreeSpinsMode(setGameMode())) {
        if (
          setCurrentBonus().isActive &&
          setCurrentBonus().rounds === setCurrentBonus().currentRound
        ) {
          setCurrentBonus({ ...setCurrentBonus(), isActive: false });
          this.endFreeSpins();
        } else {
          this.skipAnimations();
          setTimeout(
            () => eventManager.emit(EventTypes.NEXT_FREE_SPINS_ROUND),
            setCurrentBonus().currentRound === 0 ? 0 : 500,
          );
        }
      }
      client.writeQuery({
        query: isStoppedGql,
        data: {
          isSlotStopped: true,
        },
      });
    }
    if (state === SlotMachineState.JINGLE) {
      if (this.getBonusFromResult()) {
        if (isFreeSpinsMode(setGameMode())) {
          eventManager.emit(EventTypes.JINGLE_START);
          AudioHowl.play({ type: ISongs.LvUpIconSE });
          const jingleDelay = Tween.createDelayAnimation(
            mappedAudioSprites[ISongs.LvUp].duration,
          );
          jingleDelay.addOnStart(() => {
            AudioHowl.play({ type: ISongs.LvUp, stopPrev: true });
          });
          jingleDelay.addOnComplete(() => {
            this.setState(SlotMachineState.WINNING);
          });
          jingleDelay.start();
        } else {
          const jingleDelay = Tween.createDelayAnimation(
            mappedAudioSprites[ISongs.FeatureTrigger].duration,
          );
          jingleDelay.addOnStart(() => {
            AudioHowl.play({ type: ISongs.FeatureTrigger, stopPrev: true });
          });
          jingleDelay.addOnComplete(() => {
            this.setState(SlotMachineState.WINNING);
          });
          jingleDelay.start();
        }
      } else {
        this.setState(SlotMachineState.WINNING);
      }
    }
    if (state === SlotMachineState.WINNING) {
      if (this.hasWin()) {
        eventManager.emit(
          EventTypes.START_WIN_ANIMATION,
          this.nextResult!,
          false,
        );
      } else {
        if (!isFreeSpinsMode(setGameMode())) {
          eventManager.emit(
            EventTypes.UPDATE_USER_BALANCE,
            this.nextResult?.balance.settled,
          );
        }
        this.onCountUpEnd();
      }
    }
  }
}

export default SlotMachine;
