import * as PIXI from 'pixi.js';

import { isMobile } from 'mobile-device-detect';
import { EventTypes, FreeSpinsTitleProps } from '../../global.d';
import { isMobilePortrait } from '../../utils';
import ViewContainer from '../components/container';
import { eventManager } from '../config';

import {
  FREESPINS_PANEL_MARGIN_X,
  FREESPINS_PANEL_MARGIN_Y,
  FREESPINS_PANEL_PORTRAIT_SCALE,
  FREESPINS_PANEL_POS_X_LANDSCAPE,
  FREESPINS_PANEL_POS_X_PORTRAIT,
  FREESPINS_PANEL_POS_Y,
} from './config';
import { FreeSpinCounter } from './freeSpinCounter';
import { LevelIndicater } from './indicater/levelIndicater';

export class FreeSpinsPanel extends ViewContainer {
  private indicater: LevelIndicater;

  private spinCounter: FreeSpinCounter;

  private handleResize = this.onResize.bind(this);

  constructor(props: FreeSpinsTitleProps) {
    super();

    this.indicater = new LevelIndicater();
    this.addChild(this.indicater);

    this.spinCounter = new FreeSpinCounter(props);
    this.addChild(this.spinCounter);

    this.position.set(FREESPINS_PANEL_POS_X_LANDSCAPE, FREESPINS_PANEL_POS_Y);
    this.onResize(window.innerWidth, window.innerHeight);

    eventManager.addListener(EventTypes.RESIZE, this.handleResize);
  }

  private onResize(width: number, height: number): void {
    if (isMobile && isMobilePortrait(width, height)) {
      this.setPortraitLayout();
    } else {
      this.setLandscapeLayout();
    }
  }

  private setLandscapeLayout(): void {
    this.reset();

    let posX = 0;
    const posY = this.height / 2;

    [this.indicater, this.spinCounter].forEach((item) => {
      item.pivot.set(0, item.height / 2);
      item.position.set(posX, posY);
      posX += item.width + FREESPINS_PANEL_MARGIN_X;
    });

    this.pivot.set(this.width / 2, this.height);
    this.position.x = FREESPINS_PANEL_POS_X_LANDSCAPE;
  }

  private setPortraitLayout(): void {
    this.reset();

    const posX = this.width / 2;
    let posY = 0;

    [this.indicater, this.spinCounter].forEach((item) => {
      item.pivot.set(item.width / 2, 0);
      item.position.set(posX, posY);
      posY += item.height + FREESPINS_PANEL_MARGIN_Y;
    });

    this.pivot.set(this.width / 2, this.height);
    this.position.x = FREESPINS_PANEL_POS_X_PORTRAIT;
    this.scale.set(FREESPINS_PANEL_PORTRAIT_SCALE);
  }

  private reset(): void {
    this.scale.set(1, 0.95);
    this.children.forEach((i) => i.position.set(0, 0));
  }

  // override destroy(options?: {
  public destroy(options?: { children?: boolean; texture?: boolean; baseTexture?: boolean }): void {
    super.destroy(options);
    eventManager.removeListener(EventTypes.RESIZE, this.handleResize);
  }
}
export default FreeSpinsPanel;
