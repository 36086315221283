import _ from 'lodash';

import { MAPPED_SYMBOLS, MAPPED_SYMBOLS_STOP_ANIMATIONS } from '../../config';
import { EventTypes } from '../../global.d';
import { setIsErrorMessage, setNextResult, setUserLastBetResult } from '../../gql';
import { destroySpine, isScatter } from '../../utils';
import Animation from '../animations/animation';
import SpineAnimation from '../animations/spine';
import Tween from '../animations/tween';
import ViewContainer from '../components/container';
import {
  ANTICIPATION_SLOTS_TINT,
  GAME_CONTAINER_HEIGHT,
  GAME_CONTAINER_WIDTH,
  REELS_AMOUNT,
  REEL_WIDTH,
  SLOTS_PER_REEL_AMOUNT,
  SLOT_HEIGHT,
  SLOT_SCALE,
  SLOT_WIDTH,
  eventManager,
} from '../config';
import { Icon } from '../d';

export class SlotsStopDisplayContainer extends ViewContainer {
  private screenDisplay: Icon[] = [];

  private stopSymbolAnimations: Animation[] = [];

  private slotSprites: PIXI.Sprite[] = [];

  constructor(spinResult: Icon[]) {
    super();
    this.width = GAME_CONTAINER_WIDTH;
    this.height = GAME_CONTAINER_HEIGHT;
    this.screenDisplay = spinResult;
    for (let i = 0; i < SLOTS_PER_REEL_AMOUNT; i++) {
      for (let j = 0; j < REELS_AMOUNT; j++) {
        const sprite = new PIXI.Sprite(PIXI.Texture.from(MAPPED_SYMBOLS[spinResult[i * REELS_AMOUNT + j].id]));
        sprite.width = SLOT_WIDTH * SLOT_SCALE;
        sprite.height = SLOT_HEIGHT * SLOT_SCALE;
        sprite.anchor.set(0.5, 0.5);
        sprite.x = REEL_WIDTH * j + REEL_WIDTH / 2;
        sprite.y = SLOT_HEIGHT * i + SLOT_HEIGHT / 2;
        this.addChild(sprite);
        this.slotSprites.push(sprite);
      }
    }
    eventManager.addListener(EventTypes.START_SPIN_ANIMATION, this.skipStopSymbolAnimations.bind(this));
    eventManager.addListener(EventTypes.ANTICIPATION_ANIMATIONS_START, this.onAnticipationAnimationStarts.bind(this));
    eventManager.addListener(EventTypes.ANTICIPATION_ANIMATIONS_END, this.resetSlotsTint.bind(this));
    eventManager.addListener(EventTypes.HIDE_STOP_SLOTS_DISPLAY, this.hideContainer.bind(this));
    eventManager.addListener(EventTypes.SHOW_STOP_SLOTS_DISPLAY, this.showSlotStops.bind(this));
    eventManager.addListener(EventTypes.REEL_STOPPED, this.onReelStopped.bind(this));
    eventManager.addListener(EventTypes.JINGLE_START, this.skipScatterAnnounce.bind(this));
    eventManager.addListener(EventTypes.SET_SLOTS_VISIBILITY, this.handleSetSlotsVisibility.bind(this));
  }

  private skipScatterAnnounce(): void {
    this.stopSymbolAnimations.forEach((animation) => animation.skip());
  }

  private onAnticipationAnimationStarts(): void {
    this.slotSprites.forEach((slot, index) => {
      if (!isScatter(setNextResult()!.bet.result.spinResult[index].id)) {
        slot.tint = ANTICIPATION_SLOTS_TINT;
      } else {
        slot.zIndex = 3;
      }
    });
  }

  private resetSlotsTint(): void {
    this.slotSprites.forEach((slot) => {
      slot.tint = 0xffffff;
    });
  }

  private onReelStopped(reelId: number): void {
    if (!setIsErrorMessage()) {
      eventManager.emit(EventTypes.SHOW_STOP_SLOTS_DISPLAY, setNextResult()!.bet.result.spinResult, reelId);
    }
  }

  private skipStopSymbolAnimations(): void {
    this.stopSymbolAnimations.forEach((animation) => animation.skip());
    this.stopSymbolAnimations = [];
  }

  private handleSetSlotsVisibility(slots: number[], visible: boolean): void {
    for (let i = 0; i < slots.length; i++) {
      this.slotSprites[slots[i]].visible = visible;
    }
  }

  private showSlotStops(spinResult: Icon[], reelId?: number): void {
    if (reelId !== undefined) {
      for (let i = 0; i < SLOTS_PER_REEL_AMOUNT; i++) {
        this.slotSprites[i * REELS_AMOUNT + reelId].texture = PIXI.Texture.from(
          MAPPED_SYMBOLS[spinResult[i * REELS_AMOUNT + reelId].id],
        );
        this.slotSprites[i * REELS_AMOUNT + reelId].visible = true;
      }
    } else {
      for (let i = 0; i < spinResult.length; i++) {
        this.slotSprites[i].texture = PIXI.Texture.from(MAPPED_SYMBOLS[spinResult[i].id]);
        this.slotSprites[i].visible = true;
      }
    }
  }

  private hideContainer(): void {
    this.slotSprites.forEach((sprite) => (sprite.visible = false));
  }
}
