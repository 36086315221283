import { SLOTS_CONTAINER_WIDTH } from '../config';

export const FREESPINS_PANEL_MARGIN_X = 9;
export const FREESPINS_PANEL_MARGIN_Y = 17;

export const FREESPINS_PANEL_POS_X_LANDSCAPE = SLOTS_CONTAINER_WIDTH / 2 - 175;
export const FREESPINS_PANEL_POS_X_PORTRAIT = SLOTS_CONTAINER_WIDTH / 2;
export const FREESPINS_PANEL_POS_Y = 105;

export const FREESPINS_PANEL_PORTRAIT_SCALE = 1.5;

export const FREE_SPIN_ANIMATION_SCALE = 1.3;
export const FREE_SPIN_ANIMATION_DELAY = 500;
export const FREE_SPIN_ANIMATION_LOOP = false;
