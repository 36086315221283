import _ from 'lodash';
import * as PIXI from 'pixi.js';

import AudioHowl from '@phoenix7dev/play-music';

import { ISongs, SlotId } from '../../config';
import { EventTypes } from '../../global.d';
import { setGameMode } from '../../gql';
import { destroySpine, isFreeSpinsMode, isScatter } from '../../utils';
import Animation from '../animations/animation';
import { TweenProperties } from '../animations/d';
import SpineAnimation from '../animations/spine';
import Tween from '../animations/tween';
import ViewContainer from '../components/container';
import {
  ADDITIONAL_SPIN_TIME_PER_REEL,
  ANTICIPATION_DURATION,
  ANTICIPATION_SLOTS_TINT,
  BASE_REEL_ENDING_DURATION,
  BASE_REEL_ENDING_FORMULA,
  BASE_REEL_ROLLING_DURATION,
  BASE_REEL_STARTING_DURATION,
  BASE_REEL_STARTING_FORMULA,
  FAKE_ROLLING_DURATION,
  FAKE_ROLLING_SLOTS,
  INIT_SLOTS_AMOUNT_SPIN_BEFORE_STOP,
  MINIMUM_SPIN_SLOTS_AMOUNT,
  REEL_ENDING_SLOTS_AMOUNT,
  REEL_STARTING_SLOTS_AMOUNT,
  REEL_WIDTH,
  ReelState,
  SLOTS_CONTAINER_HEIGHT,
  SPIN_REEL_ANIMATION_DELAY_PER_REEL,
  TURBO_ADDITIONAL_SPIN_TIME_PER_REEL,
  TURBO_REEL_ENDING_DURATION,
  TURBO_REEL_ROLLING_DURATION,
  TURBO_REEL_STARTING_DURATION,
  TURBO_SPIN_REEL_ANIMATION_DELAY_PER_REEL,
  eventManager,
} from '../config';
import { Slot } from '../slot/slot';
import SpinAnimation from '../spin/spin';

import { IReel } from './d';

class Reel implements IReel {
  public id: number;

  public state: ReelState;

  public data: SlotId[];

  public container: ViewContainer;

  public position = 0;

  public previousPosition = 0;

  public spinAnimation: SpinAnimation | null = null;

  public slots: Slot[] = [];

  public isPlaySoundOnStop = false;

  public isTurboSpin = false;

  public size: number;

  public scatter_no = 0;

  public anticipationAnimation: Animation | undefined;

  constructor(id: number, data: SlotId[], startPosition: number) {
    this.id = id;
    this.data = data;
    this.size = data.length;
    this.state = ReelState.IDLE;
    this.container = new ViewContainer();
    this.container.width = REEL_WIDTH;
    this.container.x = id * REEL_WIDTH;
    this.container.y = 0;
    this.container.sortableChildren = true;
    this.createSlots();
    this.position = this.size - startPosition;
    eventManager.addListener(EventTypes.ANTICIPATION_STARTS, this.onAnticipationStart.bind(this));
    eventManager.addListener(EventTypes.ANTICIPATION_ANIMATIONS_START, this.onAnticipationAnimationStarts.bind(this));
    eventManager.addListener(EventTypes.ANTICIPATION_ANIMATIONS_END, this.resetSlotsTint.bind(this));
    eventManager.addListener(EventTypes.REELS_STOPPED, this.onReelsStopped.bind(this));
    eventManager.addListener(EventTypes.REMOVE_ANTICIPATION_TINT, this.resetSlotsTint.bind(this));
  }

  public init(data: SlotId[], position: number): void {
    this.data = data;
    this.size = data.length;
    this.createSlots();
    this.position = position;
  }

  public clean(): void {
    this.container.removeChildren();
    this.slots = [];
  }

  private createAnticipationBackgroundAnimation(): Animation {
    const dummy = Tween.createDelayAnimation(ANTICIPATION_DURATION);
    const animation = new SpineAnimation({}, PIXI.Loader.shared.resources.near_miss.spineData);

    dummy.addOnStart(() => {
      animation.getSpine().x = REEL_WIDTH / 2;
      animation.getSpine().y = SLOTS_CONTAINER_HEIGHT / 2;
      animation.getSpine().state.timeScale = 1;
      this.container.addChild(animation.getSpine());
      animation.setAnimation('near_miss', false);
    });
    dummy.addOnComplete(() => {
      this.container.removeChild(animation.getSpine());
      destroySpine(animation);
    });
    dummy.addOnSkip(() => {
      this.container.removeChild(animation.getSpine());
      destroySpine(animation);
    });
    return dummy;
  }

  private onAnticipationAnimationStarts(): void {
    _.forEach(this.slots, (slot) => {
      if (!isScatter(slot.slotId)) {
        slot.tint = ANTICIPATION_SLOTS_TINT;
      } else {
        slot.zIndex = 3;
      }
    });
  }

  private onAnticipationStart(index: number): void {
    if (this.id === index && !this.anticipationAnimation) {
      this.anticipationAnimation = this.createAnticipationBackgroundAnimation();
      this.anticipationAnimation.start();
    }
    AudioHowl.play({ type: ISongs.LongSpin, stopPrev: true });
  }

  private onReelsStopped(): void {
    // this.resetSlotsTint();
  }

  private resetSlotsTint(): void {
    _.forEach(this.slots, (slot) => {
      slot.tint = 0xffffff;
    });
  }

  private createSlots(): void {
    for (let i = 0; i < this.data.length; i++) {
      const slotId = this.data[i % this.data.length];
      const slot = new Slot(i, slotId);
      this.slots.push(slot);
      this.container.addChild(slot);
    }
  }

  public getTarget(expected: number): number {
    if (expected - this.position > MINIMUM_SPIN_SLOTS_AMOUNT) {
      return expected;
    }
    let amount = expected - this.position;
    while (amount < MINIMUM_SPIN_SLOTS_AMOUNT) amount += this.data.length;
    return amount + this.position;
  }

  public createSpinAnimation(isTurboSpin: boolean | undefined): SpinAnimation {
    this.position %= this.data.length;
    this.isTurboSpin = !!isTurboSpin;
    const rollingTime = isTurboSpin
      ? TURBO_REEL_ROLLING_DURATION + this.id * TURBO_ADDITIONAL_SPIN_TIME_PER_REEL
      : BASE_REEL_ROLLING_DURATION + this.id * ADDITIONAL_SPIN_TIME_PER_REEL;
    const target = this.position + INIT_SLOTS_AMOUNT_SPIN_BEFORE_STOP + this.id * 5;

    const starting = new Tween({
      object: this,
      property: TweenProperties.POSITION,
      propertyBeginValue: this.position,
      target: this.position + REEL_STARTING_SLOTS_AMOUNT,
      easing: BASE_REEL_STARTING_FORMULA,
      delay: (isTurboSpin ? TURBO_SPIN_REEL_ANIMATION_DELAY_PER_REEL : SPIN_REEL_ANIMATION_DELAY_PER_REEL) * this.id,
      duration: isTurboSpin ? TURBO_REEL_STARTING_DURATION : BASE_REEL_STARTING_DURATION,
    });
    starting.addOnStart(() => {
      this.changeState(ReelState.STARTING);
    });
    const fakeRolling = new Tween({
      object: this,
      property: TweenProperties.POSITION,
      propertyBeginValue: this.position + REEL_STARTING_SLOTS_AMOUNT,
      target: this.position + REEL_STARTING_SLOTS_AMOUNT + FAKE_ROLLING_SLOTS,
      duration: FAKE_ROLLING_DURATION,
    });
    fakeRolling.addOnStart(() => {
      this.changeState(ReelState.ROLLING);
    });
    const rolling = new Tween({
      object: this,
      property: TweenProperties.POSITION,
      propertyBeginValue: this.position + REEL_STARTING_SLOTS_AMOUNT,
      target: target - REEL_ENDING_SLOTS_AMOUNT,
      duration: rollingTime,
    });
    const ending = new Tween({
      object: this,
      property: TweenProperties.POSITION,
      propertyBeginValue: target - REEL_ENDING_SLOTS_AMOUNT,
      target,
      easing: BASE_REEL_ENDING_FORMULA,
      duration: isTurboSpin ? TURBO_REEL_ENDING_DURATION : BASE_REEL_ENDING_DURATION,
    });
    ending.addOnStart(() => {
      this.changeState(ReelState.ENDING);
    });
    ending.addOnComplete(() => {
      this.changeState(ReelState.IDLE);
      this.onReelStop();
    });
    this.spinAnimation = new SpinAnimation({
      startingAnimation: starting,
      fakeRollingAnimation: fakeRolling,
      rollingAnimation: rolling,
      endingAnimation: ending,
    });
    return this.spinAnimation;
  }

  private onReelEnding(previousState: ReelState, newState: ReelState): void {}

  private onReelStop(): void {
    this.anticipationAnimation?.skip();
    this.anticipationAnimation = undefined;
    AudioHowl.stop({ type: ISongs.LongSpin });
    const reelStopSoundList = [
      ISongs.SFX_UI_SpinStop,
      ISongs.Scatter_01,
      ISongs.Scatter_02,
      ISongs.Scatter_03,
      ISongs.Scatter_04,
      ISongs.Scatter_05,
    ];

    if (this.isPlaySoundOnStop) {
      const offset = isFreeSpinsMode(setGameMode()) ? 3 : 2;
      const idx = this.scatter_no > this.id - offset ? this.scatter_no : 0;
      AudioHowl.play({
        type: reelStopSoundList[idx],
        stopPrev: true,
      });
      this.isPlaySoundOnStop = false;
      this.scatter_no = 0;
    }
  }

  private onReelIdle(previousState: ReelState, newState: ReelState): void {
    if (previousState === ReelState.ENDING) {
      eventManager.emit(EventTypes.REEL_STOPPED, this.id);
      const reelStopSlots = this.getReelStopSlots(Math.round(this.position));
      _.forEach(reelStopSlots, (slot) => {
        slot.onSlotStopped();
      });
    }
  }

  public stopReel(endingDuration: number): void {
    this.spinAnimation!.getStarting().end();
    this.spinAnimation!.getFakeRolling().end();
    this.spinAnimation!.getRolling().end();
    this.spinAnimation!.getEnding().duration = endingDuration;
  }

  private getReelStopSlots(position: number): Array<Slot> {
    const slots: Array<Slot> = [];
    const top = this.slots.length - ((position % this.slots.length) + 1);
    const middle = position % this.slots.length === 0 ? 0 : this.slots.length - (position % this.slots.length);
    const bottom = (this.slots.length - ((position % this.slots.length) - 1)) % this.slots.length;
    const extra = (this.slots.length - ((position % this.slots.length) - 2)) % this.slots.length;
    slots.push(this.slots[top]);
    slots.push(this.slots[middle]);
    slots.push(this.slots[bottom]);
    slots.push(this.slots[extra]);
    return slots;
  }

  private onReelRolling(previousState: ReelState, newState: ReelState): void {}

  private onReelStarting(previousState: ReelState, newState: ReelState): void {}

  public changeState(newState: ReelState): void {
    const previousState = this.state;
    this.state = newState;
    if (newState === ReelState.IDLE) {
      this.onReelIdle(previousState, ReelState.IDLE);
    }
    if (newState === ReelState.ROLLING) {
      this.onReelRolling(previousState, ReelState.ROLLING);
    }
    if (newState === ReelState.STARTING) {
      this.onReelStarting(previousState, ReelState.STARTING);
    }
    if (newState === ReelState.ENDING) {
      this.onReelEnding(previousState, ReelState.ENDING);
    }
  }
}

export default Reel;
