import { isMobile } from 'mobile-device-detect';
import variables from '../../assets/styles/export.module.scss';
import { EventTypes } from '../../global.d';
import { setIsPortrait, setSkipIntroScreen } from '../../gql';
import { calcBottomContainerHeight, isPortrait } from '../../utils';
import ViewContainer from '../components/container';
import { SAFE_AREA_DESKTOP_BOTTOM_PADDING, SAFE_AREA_MOBILE_BOTTOM_PADDING, eventManager } from '../config';

class SafeArea extends ViewContainer {
  private isEnabledFreeSpins: boolean;

  constructor() {
    super();
    this.isEnabledFreeSpins = false;
    if (!setSkipIntroScreen()) this.visible = false;
    eventManager.addListener(
      EventTypes.IS_ENABLED_FREE_SPINS_FEATURE,
      (isEnabled: boolean) => (this.isEnabledFreeSpins = isEnabled),
    );
    eventManager.addListener(EventTypes.HIDE_SAFE_AREA, () => (this.visible = false));
    eventManager.addListener(EventTypes.SHOW_SAFE_AREA, () => (this.visible = true));
    eventManager.addListener(EventTypes.RESIZE, this.resize.bind(this));
  }

  private calculateSize(width: number, height: number): number[] {
    let newWidth = 0;
    let newHeight = 0;

    const bottomContainerHeight = calcBottomContainerHeight(width, height);
    const bottomPadding = isMobile ? SAFE_AREA_MOBILE_BOTTOM_PADDING : SAFE_AREA_DESKTOP_BOTTOM_PADDING;
    const bottomSpace = bottomContainerHeight + bottomPadding;

    const isRectangleRatio = +(width / height).toFixed(2) >= +(4 / 3).toFixed(2);
    if (isRectangleRatio) {
      if (width >= parseInt(variables.breakpointMobilePortraitMax, 10) && isMobile && this.isEnabledFreeSpins) {
        const isReduced = width < ((height - bottomSpace) * 4) / 3;
        const nHeight = isReduced ? height : height;
        newWidth = ((nHeight - bottomSpace) * 4) / 3;
        newHeight = nHeight - bottomSpace;
      } else {
        newWidth = ((height - bottomSpace) * 4) / 3;
        newHeight = height - bottomSpace;
      }
    } else if (
      width >= parseInt(variables.breakpointMobilePortraitMax, 10) &&
      width > height &&
      isMobile &&
      this.isEnabledFreeSpins
    ) {
      const nWidth = width;
      newWidth = nWidth;
      newHeight = nWidth * 0.75 - bottomSpace;
    } else {
      newWidth = width;
      newHeight = width * 0.75 - bottomSpace;
    }

    return [newWidth, newHeight, bottomSpace];
  }

  private resize(width: number, height: number): void {
    const [newWidth, newHeight, bottomSpace] = this.calculateSize(width, height);
    if (setIsPortrait() !== isPortrait(width, height)) {
      setIsPortrait(isPortrait(width, height));
      eventManager.emit(EventTypes.CHANGE_DEVICE_ORIENTATION, isPortrait(width, height), width, height);
    }
    this.position.set(Math.max((width - newWidth) / 2, 0), Math.max((height - newHeight - bottomSpace) / 2, 0));
    this.width = newWidth;
    this.height = newHeight;
    this.scale.set(1, 1);
    eventManager.emit(EventTypes.RESIZE_GAME_CONTAINER, newWidth, newHeight, this.position);
  }
}
export default SafeArea;
