import * as PIXI from 'pixi.js';

import { SlotId } from '../../config';

export * from './textStyles';
export const eventManager = new PIXI.utils.EventEmitter();

window.eventManager = eventManager; // Trick to proxy eventManager to cheats file

export enum SlotMachineState {
  IDLE,
  SPIN,
  STOP,
  JINGLE,
  WINNING,
}
// APPLICATION SETTINGS
export const APPLICATION_TRANSPARENT = false;
export const APPLICATION_FPS = 60;

// WIN SETTINGS
export const WIN_ANIMATION_DELAY_TIME_DURATION = 2_000;
export const WIN_CHECK_TIME_DURATION_ON_FREE_SPIN = 1_000;

// BASE WIN SETTINGS
export const DOUBLE_WIN_AMOUNT_LIMIT = 2;
export const BASE_WIN_AMOUNT_LIMIT = 10;
export const BASE_WIN_TITLE_SCALE = 1.2;
export const BASE_WIN_COUNT_UP_MULTIPLIER = 0.03;

// BIG WIN SETTINGS
export const BIG_WIN_TITLE = 'BIG WIN!';
export const BIG_WIN_AMOUNT_LIMIT = 25;
export const BIG_WIN_TITLE_SCALE = 1.3;
export const BIG_WIN_ZOOM_TITLE_DURATION = 500;
export const BIG_WIN_COUNT_UP_MULTIPLIER = 0.03;
export const BIG_WIN_END_DURATION = 1000;

// MEGA WIN SETTINGS
export const MEGA_WIN_TITLE = 'MEGA WIN!';
export const MEGA_WIN_AMOUNT_LIMIT = 50;
export const MEGA_WIN_TITLE_SCALE = 1.4;
export const MEGA_WIN_ZOOM_TITLE_DURATION = 550;
export const MEGA_WIN_COUNT_UP_MULTIPLIER = 0.1;

// GREAT WIN SETTINGS
export const GREAT_WIN_TITLE = 'GREAT WIN!';
export const GREAT_WIN_AMOUNT_LIMIT = 100;
export const GREAT_WIN_TITLE_SCALE = 1.5;
export const GREAT_WIN_ZOOM_TITLE_DURATION = 600;
export const GREAT_WIN_COUNT_UP_MULTIPLIER = 0.2;

// EPIC WIN SETTINGS
export const EPIC_WIN_TITLE = 'EPIC WIN!';
export const EPIC_WIN_TITLE_SCALE = 1.6;
export const EPIC_WIN_ZOOM_TITLE_DURATION = 650;
export const EPIC_WIN_COUNT_UP_MULTIPLIER = 0.5;

// WIN SLOT ANIMATION SETTINGS
export const WIN_SLOT_ANIMATION_SCALE = 1.15;
export const WIN_SLOT_ANIMATION_DURATION = 600;
export const TURBO_SPIN_WIN_SLOT_ANIMATION_COEFFICIENT = 1;

// SPINE FULL SCREEN SIZE
export const LANDSCAPE_FULL_SCREEN_WIDTH = 1920;
export const LANDSCAPE_FULL_SCREEN_HEIGHT = 1080;
export const PORTRAIT_FULL_SCREEN_WIDTH = 414;
export const PORTRAIT_FULL_SCREEN_HEIGHT = 736;

// GENERAL SLOT MACHINE SETTINGS
export const REELS_AMOUNT = 5;
export const SLOTS_PER_REEL_AMOUNT = 4;
export const REEL_WIDTH = 247.5;
export const SLOT_WIDTH = 237.5;
export const SLOT_HEIGHT = 190;
export const SLOT_SCALE = 1.2;
export const SHOW_ALL_LINES_ON_WIN = true;
export const GAME_CONTAINER_HEIGHT = SLOT_HEIGHT * SLOTS_PER_REEL_AMOUNT;
export const SLOTS_CONTAINER_WIDTH = REEL_WIDTH * REELS_AMOUNT;
export const SLOTS_CONTAINER_HEIGHT = SLOT_HEIGHT * SLOTS_PER_REEL_AMOUNT;
export const SHOW_LINES_NUMBER_CONTAINER = false;
export const LINES_NUMBER_CONTAINER_WIDTH = 30;
export const MINIMUM_SPIN_SLOTS_AMOUNT = 50;
export const GAME_CONTAINER_WIDTH = SHOW_LINES_NUMBER_CONTAINER
  ? SLOTS_CONTAINER_WIDTH + 2 * LINES_NUMBER_CONTAINER_WIDTH
  : SLOTS_CONTAINER_WIDTH;

// ANTICIPATION SETTINGS
export const ANTICIPATION_ENABLE = true;
export const ANTICIPATION_SYMBOLS_ID = [SlotId.SC2, SlotId.SC3, SlotId.SC4, SlotId.SC5, SlotId.SC6];
export const ANTICIPATION_START_SYMBOLS_AMOUNT = [1, 1, 1, 1, 1];
export const ANTICIPATION_END_SYMBOLS_AMOUNT = [2, 2, 2, 2, 2];
export const ANTICIPATION_DURATION = 2_000;
export const ANTICIPATION_REEL_ENDING_SLOTS_AMOUNT = 15;
export const ANTICIPATION_SLOTS_TINT = 0xaaaaaa;

// SAFE AREA SETTINGS
export const SAFE_AREA_DESKTOP_BOTTOM_PADDING = 36;
export const SAFE_AREA_MOBILE_BOTTOM_PADDING = 0;

// REELS SETTINGS
export const BASE_SPIN_TIME = 2000;
export const TURBO_SPIN_TIME = 1000;
export const ADDITIONAL_SPIN_TIME_PER_REEL = 300;
export const TURBO_ADDITIONAL_SPIN_TIME_PER_REEL = 100;
export const TURBO_SPIN_REEL_ANIMATION_DELAY_PER_REEL = 25;
export const SPIN_REEL_ANIMATION_DELAY_PER_REEL = 50;
// export const PERSISTENT_SHARP_SYMBOLS_ID = [SlotId.SC1, SlotId.WL, SlotId.MS1];
export const BASE_REEL_BLUR_VALUE = 18;
export const TURBO_REEL_BLUR_VALUE = 24;
export enum ReelState {
  IDLE,
  STARTING,
  ROLLING,
  ENDING,
}

// SPIN ANIMATION SETTINGS
export const INIT_SLOTS_AMOUNT_SPIN_BEFORE_STOP = 50;
export const BASE_REEL_STARTING_DURATION = 500;
export const BASE_REEL_ROLLING_DURATION = 1250;
export const BASE_REEL_ENDING_DURATION = 250;
export const FAKE_ROLLING_DURATION = 13_000;
export const FAKE_ROLLING_SLOTS = 400;
export const TURBO_REEL_STARTING_DURATION = 250;
export const TURBO_REEL_ROLLING_DURATION = 500;
export const TURBO_REEL_ENDING_DURATION = 250;
export const FORCE_STOP_SPIN_ANIMATION_DURATION = 250;
export const FORCE_STOP_SPIN_PER_EACH_DURATION = 50;
export const REEL_STARTING_SLOTS_AMOUNT = 3;
export const REEL_MYSTERY_SLOTS_AMOUNT = 120;
export const REEL_ENDING_SLOTS_AMOUNT = 3;
export const BASE_REEL_STARTING_FORMULA = (t: number): number => 3 * t * t * (1.4 * t - 0.4);
export const BASE_REEL_ROLLING_FORMULA = (t: number): number => -0.592691 + 1.77055 * t;
export const BASE_REEL_ENDING_FORMULA = (t: number): number => (t - 1) * (t - 1) * ((0.5 + 1) * (t - 1) + 0.5) + 1;
export const ANTICIPATION_REEL_ROLLING_FORMULA = (t: number): number => -0.6884 + 2.19771 * t;
export const ANTICIPATION_REEL_ENDING_FORMULA = (t: number): number => 0.5 + 0.5 * t;

// TINT CONTAINER SETTINGS
export const TINT_CONTAINER_WIDTH = SLOTS_CONTAINER_WIDTH;
export const TINT_CONTAINER_HEIGHT = SLOTS_CONTAINER_HEIGHT;
export const TINT_BACKGROUND_COLOR = 0x000000;
export const TINT_ALPHA = 0.2;

// PAY_TABLE SETTINGS
export const PAY_TABLE_WIDTH = REEL_WIDTH * 1.5;
export const PAY_TABLE_HEIGHT = SLOT_HEIGHT * 0.75;
export const PAY_TABLE_BACKGROUND_COLOR = 0x000000;
export const PAY_TABLE_NUMBER_COLOR = 0xffffff;
export const PAY_TABLE_TEXT_COLOR = 0xf6e87e;

// COINS ANIMATIONS SETTINGS
export const COINS_AMOUNT_PER_SEC = 8;
export const SINGLE_COIN_ANIMATION_DURATION = 2500;
export const MINIMUM_FRACTION_DIGITS = 2;
export const MAXIMUM_FRACTION_DIGITS = 2;

// FREE SPINS
export const FREE_SPINS_FADE_DURATION = 4000;
export const FREE_SPINS_TIME_OUT_RETRIGGER_BANNER = 5000;
export const FREE_SPINS_LVL_UP_CHANGES_DELAY = 1000;
export const FREE_SPINS_TIME_OUT_BANNER = 1000;

export const WIN_CHECK_TIME_DURATION_ON_FREESPIN = 1_000;
export const BIG_WIN_CHECK_TIME_DURATION_ON_FREESPIN = 3_000;
// WIN STAGES
export enum WinStages {
  None,
  BaseWin,
  BigWin,
  MegaWin,
  GreatWin,
  EpicWin,
}

// LINES SETTINGS
type Position = {
  [key: number]: {
    x: number;
    y: number;
  };
};
export const POSITION: Position = {
  0: {
    x: REEL_WIDTH / 2,
    y: SLOT_HEIGHT / 2,
  },
  1: {
    x: REEL_WIDTH + REEL_WIDTH / 2,
    y: SLOT_HEIGHT / 2,
  },
  2: {
    x: REEL_WIDTH * 2 + REEL_WIDTH / 2,
    y: SLOT_HEIGHT / 2,
  },
  3: {
    x: REEL_WIDTH * 3 + REEL_WIDTH / 2,
    y: SLOT_HEIGHT / 2,
  },
  4: {
    x: REEL_WIDTH * 4 + REEL_WIDTH / 2,
    y: SLOT_HEIGHT / 2,
  },
  5: {
    x: REEL_WIDTH / 2,
    y: SLOT_HEIGHT + SLOT_HEIGHT / 2,
  },
  6: {
    x: REEL_WIDTH + REEL_WIDTH / 2,
    y: SLOT_HEIGHT + SLOT_HEIGHT / 2,
  },
  7: {
    x: REEL_WIDTH * 2 + REEL_WIDTH / 2,
    y: SLOT_HEIGHT + SLOT_HEIGHT / 2,
  },
  8: {
    x: REEL_WIDTH * 3 + REEL_WIDTH / 2,
    y: SLOT_HEIGHT + SLOT_HEIGHT / 2,
  },
  9: {
    x: REEL_WIDTH * 4 + REEL_WIDTH / 2,
    y: SLOT_HEIGHT + SLOT_HEIGHT / 2,
  },
  10: {
    x: REEL_WIDTH / 2,
    y: SLOT_HEIGHT * 2 + SLOT_HEIGHT / 2,
  },
  11: {
    x: REEL_WIDTH + REEL_WIDTH / 2,
    y: SLOT_HEIGHT * 2 + SLOT_HEIGHT / 2,
  },
  12: {
    x: REEL_WIDTH * 2 + REEL_WIDTH / 2,
    y: SLOT_HEIGHT * 2 + SLOT_HEIGHT / 2,
  },
  13: {
    x: REEL_WIDTH * 3 + REEL_WIDTH / 2,
    y: SLOT_HEIGHT * 2 + SLOT_HEIGHT / 2,
  },
  14: {
    x: REEL_WIDTH * 4 + REEL_WIDTH / 2,
    y: SLOT_HEIGHT * 2 + SLOT_HEIGHT / 2,
  },
  15: {
    x: REEL_WIDTH / 2,
    y: SLOT_HEIGHT * 3 + SLOT_HEIGHT / 2,
  },
  16: {
    x: REEL_WIDTH + REEL_WIDTH / 2,
    y: SLOT_HEIGHT * 3 + SLOT_HEIGHT / 2,
  },
  17: {
    x: REEL_WIDTH * 2 + REEL_WIDTH / 2,
    y: SLOT_HEIGHT * 3 + SLOT_HEIGHT / 2,
  },
  18: {
    x: REEL_WIDTH * 3 + REEL_WIDTH / 2,
    y: SLOT_HEIGHT * 3 + SLOT_HEIGHT / 2,
  },
  19: {
    x: REEL_WIDTH * 4 + REEL_WIDTH / 2,
    y: SLOT_HEIGHT * 3 + SLOT_HEIGHT / 2,
  },
};

// LINE NUMBERS CONTAINER SETTINGS
export const LINES_NUMBER_CONTAINER_HEIGHT = SLOTS_CONTAINER_HEIGHT;
export const LEFT_LINES_NUMBERS_CONTAINER_POSITIONS_X = 5;
export const RIGHT_LINES_NUMBERS_CONTAINER_POSITIONS_X = 0;
export const LEFT_LINES_NUMBERS_CONTAINER_POSITIONS = [
  { id: 0, x: LEFT_LINES_NUMBERS_CONTAINER_POSITIONS_X, y: 0 },
  { id: 1, x: LEFT_LINES_NUMBERS_CONTAINER_POSITIONS_X, y: 35 },
  { id: 2, x: LEFT_LINES_NUMBERS_CONTAINER_POSITIONS_X, y: 70 },
  { id: 3, x: LEFT_LINES_NUMBERS_CONTAINER_POSITIONS_X, y: 105 },
  { id: 4, x: LEFT_LINES_NUMBERS_CONTAINER_POSITIONS_X, y: 140 },
  { id: 5, x: LEFT_LINES_NUMBERS_CONTAINER_POSITIONS_X, y: 175 },
  { id: 6, x: LEFT_LINES_NUMBERS_CONTAINER_POSITIONS_X, y: 210 },
  { id: 7, x: LEFT_LINES_NUMBERS_CONTAINER_POSITIONS_X, y: 245 },
  { id: 8, x: LEFT_LINES_NUMBERS_CONTAINER_POSITIONS_X, y: 280 },
  { id: 9, x: LEFT_LINES_NUMBERS_CONTAINER_POSITIONS_X, y: 315 },
  { id: 10, x: LEFT_LINES_NUMBERS_CONTAINER_POSITIONS_X, y: 350 },
  { id: 11, x: LEFT_LINES_NUMBERS_CONTAINER_POSITIONS_X, y: 385 },
];
export const RIGHT_LINES_NUMBERS_CONTAINER = [
  { id: 12, x: RIGHT_LINES_NUMBERS_CONTAINER_POSITIONS_X, y: 0 },
  { id: 13, x: RIGHT_LINES_NUMBERS_CONTAINER_POSITIONS_X, y: 32 },
  { id: 14, x: RIGHT_LINES_NUMBERS_CONTAINER_POSITIONS_X, y: 64 },
  { id: 15, x: RIGHT_LINES_NUMBERS_CONTAINER_POSITIONS_X, y: 96 },
  { id: 16, x: RIGHT_LINES_NUMBERS_CONTAINER_POSITIONS_X, y: 128 },
  { id: 17, x: RIGHT_LINES_NUMBERS_CONTAINER_POSITIONS_X, y: 160 },
  { id: 18, x: RIGHT_LINES_NUMBERS_CONTAINER_POSITIONS_X, y: 192 },
  { id: 19, x: RIGHT_LINES_NUMBERS_CONTAINER_POSITIONS_X, y: 224 },
  { id: 20, x: RIGHT_LINES_NUMBERS_CONTAINER_POSITIONS_X, y: 256 },
  { id: 21, x: RIGHT_LINES_NUMBERS_CONTAINER_POSITIONS_X, y: 288 },
  { id: 22, x: RIGHT_LINES_NUMBERS_CONTAINER_POSITIONS_X, y: 320 },
  { id: 23, x: RIGHT_LINES_NUMBERS_CONTAINER_POSITIONS_X, y: 352 },
  { id: 24, x: RIGHT_LINES_NUMBERS_CONTAINER_POSITIONS_X, y: 384 },
];

// FRAME
export const REELS_FRAME_POSITION_X = -50;
export const REELS_FRAME_POSITION_Y = 51;
export const DESKTOP_REELS_FRAME_WIDTH = GAME_CONTAINER_WIDTH + 100;
export const DESKTOP_REELS_FRAME_HEIGHT = GAME_CONTAINER_HEIGHT + 525;
export const MOBILE_REELS_FRAME_WIDTH = GAME_CONTAINER_WIDTH + 196;
export const MOBILE_REELS_FRAME_HEIGHT = GAME_CONTAINER_WIDTH + 140;
