export enum ResourceTypes {
  levelPanel = 'levelPanel',
  messageBanerPointer = 'messageBanerPointer',
  messageBannerBlock = 'messageBannerBlock',
  messageBanner = 'messageBanner',
  messageWinBanner = 'messageWinBanner',
  reelsLight1 = 'reelsLight1',
  reelsLight2 = 'reelsLight2',
  reelsLight3 = 'reelsLight3',
  reelsLight4 = 'reelsLight4',
  reelsLight5 = 'reelsLight5',
  symbolIndicatorA = 'symbolIndicatorA',
  symbolIndicatorB = 'symbolIndicatorB',
  symbolIndicatorC = 'symbolIndicatorC',
  symbolIndicatorD = 'symbolIndicatorD',
  symbolIndicatorE = 'symbolIndicatorE',
  delimiter = 'delimiter',
  frame = 'frame',
  logo = 'logo',
  blurBonus = 'blurBonus',
  blurClownFish = 'blurClownFish',
  blurCoral = 'blurCoral',
  blurDolphin = 'blurDolphin',
  blurJellyFish = 'blurJellyFish',
  blurManta = 'blurManta',
  blurMarlin = 'blurMarlin',
  blurOctopus = 'blurOctopus',
  blurSeal = 'blurSeal',
  blurShark = 'blurShark',
  blurShell = 'blurShell',
  blurTurtule = 'blurTurtule',
  blurWild = 'blurWild',
  clownFish = 'clownFish',
  coral = 'coral',
  dolphin = 'dolphin',
  jellyFish = 'jellyFish',
  manta = 'manta',
  marlin = 'marlin',
  octopus = 'octopus',
  scatter = 'scatter',
  seal = 'seal',
  shark = 'shark',
  shell = 'shell',
  turtle = 'turtle',
  wild = 'wild',
  buttonCancel = 'buttonCancel',
  buttonHistory = 'buttonHistory',
  buttonInfo = 'buttonInfo',
  buttonLobby = 'buttonLobby',
  buttonOk = 'buttonOk',
  buttonSetting = 'buttonSetting',
  infoBtnAutoplayStop = 'infoBtnAutoplayStop',
  infoBtnAutoplay = 'infoBtnAutoplay',
  infoBtnBetSettings = 'infoBtnBetSettings',
  infoBtnInfo = 'infoBtnInfo',
  infoBtnMenu = 'infoBtnMenu',
  infoBtnSound = 'infoBtnSound',
  infoBtnSpin = 'infoBtnSpin',
  infoBtnStop = 'infoBtnStop',
  infoBtnTurbo = 'infoBtnTurbo',
  infoPaylines = 'infoPaylines',
  introBg = 'introBg',
  intro1 = 'intro1',
  intro2 = 'intro2',
  intro3 = 'intro3',
  popupBg = 'popupBg',
}
