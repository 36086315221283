import _ from "lodash";
import { ANTICIPATION_ENABLE, REELS_AMOUNT, ANTICIPATION_SYMBOLS_ID, ANTICIPATION_END_SYMBOLS_AMOUNT, SLOTS_PER_REEL_AMOUNT, ANTICIPATION_START_SYMBOLS_AMOUNT, eventManager } from "../slotMachine/config";
import { Icon } from "../slotMachine/d";
import { isScatter } from "./helper";
import { EventTypes } from "../global.d";
import { setIsErrorMessage, setPrevReelsPosition, setUserLastBetResult } from "../gql";

// DUPLICATED methods coz slotmachine->index.ts is locked file.
export const getAnticipationEndReelId = (spinResult: Icon[]): number => {
    if (!ANTICIPATION_ENABLE) return REELS_AMOUNT;
    let minReelId = REELS_AMOUNT;
    _.forEach(ANTICIPATION_SYMBOLS_ID, (symbolId, i) => {
        const count = ANTICIPATION_END_SYMBOLS_AMOUNT[i];
        let currentCount = 0;
        for (let j = 0; j < REELS_AMOUNT; j++) {
            for (let i = 0; i < SLOTS_PER_REEL_AMOUNT; i++) {
                // eslint-disable-next-line no-plusplus
                if (spinResult[j + REELS_AMOUNT * i].id === symbolId) currentCount++;
            }
            if (currentCount >= count) minReelId = Math.min(minReelId, j);
        }
    });
    return minReelId + 1;
}

export const getAnticipationStartReelId = (spinResult: Icon[]): number => {
    if (!ANTICIPATION_ENABLE) return REELS_AMOUNT;
    let minReelId = REELS_AMOUNT;
    _.forEach(ANTICIPATION_SYMBOLS_ID, (symbolId, i) => {
        const count = ANTICIPATION_START_SYMBOLS_AMOUNT[i];
        let currentCount = 0;
        for (let j = 0; j < REELS_AMOUNT; j++) {
            for (let i = 0; i < SLOTS_PER_REEL_AMOUNT; i++) {
                // eslint-disable-next-line no-plusplus
                if (spinResult[j + REELS_AMOUNT * i].id === symbolId) currentCount++;
            }

            if (currentCount >= count) minReelId = Math.min(minReelId, j);
        }
    });
    return minReelId;
}

export const getScatterCount = (spinResult: Icon[]): Array<number> => {
    let count = 0;
    return _(spinResult)
        .chunk(REELS_AMOUNT)
        .unzip()
        .map((col) => {
            if (col.some((icon) => isScatter(icon.id))) {
                count += 1;
                return count;
            }
            return 0;
        })
        .value();
}

export const fallBackReelPosition = (): void => {
    setIsErrorMessage(true);
    const startPosition = setUserLastBetResult().id
        ? setUserLastBetResult().result.reelPositions
        : setPrevReelsPosition();

    eventManager.emit(
        EventTypes.SETUP_REEL_POSITIONS,
        startPosition,
        setUserLastBetResult().result.spinResult?.length ? getScatterCount(setUserLastBetResult().result.spinResult!) : [],
        setUserLastBetResult().result.spinResult?.length
            ? getAnticipationStartReelId(setUserLastBetResult().result.spinResult!)
            : 5,
        setUserLastBetResult().result.spinResult?.length
            ? getAnticipationEndReelId(setUserLastBetResult().result.spinResult!)
            : 5,
    );

    eventManager.emit(EventTypes.FORCE_STOP_REELS);
    eventManager.emit(EventTypes.FORCE_STOP_AUTOPLAY);
};
